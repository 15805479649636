import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import {StyledTreeItem} from "../common/TreeSelectionItem";
import Typography from "@mui/material/Typography";
import {
    checkBelastungsfallParameterPatient,
    fetchPatientFiles,
} from "../../../../redux";
import {connect} from "react-redux";

function PatientTreeSelectItem(props) {

    const { nodeId,
            itemName,
            belastungsfallId,
            children,
            belastungsfall,
            checkBelastungsfallParameterPatient,
            fetchPatientFiles} = props

    let bfID = belastungsfall[belastungsfallId];
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        if(bfID
            && bfID.checkedPatient
            && bfID.checkedPatient.nodeId === nodeId){
            setChecked(bfID.checkedPatient.checked)
        }else{
            setChecked(false);
        }
    }, [belastungsfall])

    const handleCheckboxClicked = event => {
        checkBelastungsfallParameterPatient(nodeId, belastungsfallId, event.target.checked)
        // now fetch the parameters of the activity.

        const implantId                 = bfID.implantActivity.implantId;
        const activityId                = bfID.checkedActivity.nodeId;
        const activityIndentationLevel  = bfID.checkedActivity.indentationlevel;
        const parameterId               = bfID.checkedParameter.nodeId;
        const parameterIndentationLevel = bfID.checkedParameter.indentationlevel;
        const patientId                 = nodeId;
        fetchPatientFiles(  implantId,
                            activityId,
                            activityIndentationLevel,
                            parameterId,
                            parameterIndentationLevel,
                            patientId);
    };


    const checkboxLabel = (check)=>{
        return(
        <div style={{ display: 'flex', alignItems: 'center' , justifyContent : 'flex-start'}}>
            <Typography>
                <Checkbox
                    data-testid={`patient-checkbox-${nodeId}-${belastungsfallId}`}
                    id={nodeId}
                    color="primary"
                    onClick={e => handleCheckboxClicked(e)}
                    checked={check}
                />
                {itemName}
            </Typography>
        </div>
        )
    }

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={checkboxLabel(checked)}
        >
            {children}
        </StyledTreeItem>
    )
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        checkBelastungsfallParameterPatient :(nodeId,
                                              belastungsfallId,
                                              checked) =>
                dispatch(checkBelastungsfallParameterPatient(   nodeId,
                                                                belastungsfallId,
                                                                checked)),
        fetchPatientFiles:(  implantId,
                             activityId,
                             activityIndentationLevel,
                             parameterId,
                             parameterIndentationLevel,
                             patientId) =>
                dispatch(fetchPatientFiles( implantId,
                                            activityId,
                                            activityIndentationLevel,
                                            parameterId,
                                            parameterIndentationLevel,
                                            patientId)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientTreeSelectItem)
