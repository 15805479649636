import {
    FETCH_FILE_DATA_FAILURE,
    FETCH_FILE_DATA_SUCCESS,
    FETCH_FILE_DATA_REQUEST,
    FETCH_DATA_PATH_DATA_FAILURE,
    FETCH_DATA_PATH_DATA_SUCCESS,
    FETCH_DATA_PATH_DATA_REQUEST,
    SAVE_DATA_PATH_URL,
} from "./FileData.type";

const initialState = {
    loading: false,
    fileDatas: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FILE_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_FILE_DATA_SUCCESS:
            const fileId = action.payload.fileId
            const fileData = action.payload.data;
            return {
                ...state,
                loading: false,
                dataPathData: {
                    ...state.dataPathData,
                },
                fileData: {

                    ...state.fileData,
                    [fileId]: {
                        ...fileData,
                    },
                },
                error: ''
            }
        case FETCH_FILE_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case FETCH_DATA_PATH_DATA_REQUEST:
            return {
                ...state,
                dataPathData: {
                    ...state.dataPathData,
                    [action.payload.fileId]: {
                        ...state.dataPathData[action.payload.fileId],
                        [action.payload.filePathId]: {
                            loading: true,
                            error: ''
                        },
                    }
                },
            }
        case FETCH_DATA_PATH_DATA_SUCCESS:
            return {
                ...state,
                dataPathData: {
                    ...state.dataPathData,
                    [action.payload.fileId]: {
                        ...state.dataPathData[action.payload.fileId],
                        [action.payload.filePathId]: {
                            loading: false,
                            filePathData: action.payload.data,
                            error: '',

                        }
                    }
                },
            }
        case FETCH_DATA_PATH_DATA_FAILURE:
            return {
                ...state,
                dataPathData: {
                    ...state.dataPathData,
                    [action.payload.fileId]: {
                        ...state.dataPathData[action.payload.fileId],
                        [action.payload.filePathId]: {
                            loading: false,
                            error: action.payload,
                        }
                    }
                },
            }
        case SAVE_DATA_PATH_URL:
            return {
                ...state,
                dataPathData: {
                    ...state.dataPathData,
                    [action.payload.fileId]: {
                        ...state.dataPathData[action.payload.fileId],
                        [action.payload.filePathId]: {
                            loading: false,
                            filePathURL: action.payload.filepathURL,
                            error: '',

                        }
                    }
                },
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
