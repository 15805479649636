import {
    FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL,
    FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL,
    FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL,
    FETCH_PATIENT_FILE_OF_BELASTUNGSFALL,
    FETCH_FILE_DATA_OF_BELASTUNGSFALL,
    SELECT_BELASTUNGSFALL_JOINT_NAME,
    CHECK_BELASTUNGSFALL_ACTIVITY,
    CHECK_BELASTUNGSFALL_PARAMETER,
    CHECK_BELASTUNGSFALL_PATIENT,
    CHECK_BELASTUNGSFALL_FILE,
    CHECK_BELASTUNGSFALL_DATA,
    SET_SLICES_BW,
    DELETE_BELASTUNGSFALL_DATA
} from "./Belastungsfall.type";

const initialState = {
    loading: false,
    belastungsfall: {},
    error: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL:
            return {
                ...state,
                loading: false,

                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],// copy old state at this belastungsfall
                        implantActivity: {
                            implantId: action.payload.implantId,
                        },

                        checkedParameter: {},
                        checkedPatient: {},
                        checkedFile: {},

                        activityParameter: {},
                        parameterPatient:{},
                        patientFile:{},
                        fileData:{},
                        slicesBW: {},
                    },
                },
                error:''
            }
        case FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL:
             return {
                loading: false,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],// copy old state at this belastungsfall
                        activityParameter: {
                            activityId: action.payload.activityId,
                        },
                    },
                },
                error:''
            }
        case FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL:
            return {
                loading: false,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],// copy old state at this belastungsfall
                        parameterPatient: {
                            parameterId: action.payload.parameterId,
                        },
                    },
                },
                error:''
            }
        case FETCH_PATIENT_FILE_OF_BELASTUNGSFALL:
            return {
                loading: false,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],// copy old state at this belastungsfall
                        patientFile: {
                            patientId: action.payload.patientId,
                        },
                    },
                },
                error:''
            }
        case FETCH_FILE_DATA_OF_BELASTUNGSFALL:
            return {
                // ...state,
                loading: false,

                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],// copy old state at this belastungsfall
                        fileData: {
                            fileId: action.payload.fileId,
                        },
                    },
                },
                error:''
            }
        case SELECT_BELASTUNGSFALL_JOINT_NAME:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId],
                        checkedActivity: {},
                        checkedParameter: {},
                        checkedPatient: {},
                        checkedFile: {},
                        parameterPatient:{},
                        patientFile:{},
                        fileData:{},
                    }
                }
            }
        case CHECK_BELASTUNGSFALL_ACTIVITY:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                        checkedActivity: {
                            nodeId: action.payload.nodeId,
                            label: action.payload.label,
                            checked: action.payload.checked,
                            indentationlevel: action.payload.indentationlevel,
                        },
                        checkedParameter: {},
                        checkedPatient: {},
                        checkedFile: {},
                        parameterPatient:{},
                        patientFile:{},
                        fileData:{},
                    }
                }
            }
        case CHECK_BELASTUNGSFALL_PARAMETER:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                        checkedParameter: {
                            nodeId: action.payload.nodeId,
                            label: action.payload.label,
                            checked: action.payload.checked,
                            indentationlevel: action.payload.indentationlevel,

                        },
                        checkedPatient: {},
                        checkedFile: {},
                        patientFile:{},
                        fileData:{},
                    }
                }
            }
        case CHECK_BELASTUNGSFALL_PATIENT:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                        checkedPatient: {
                            nodeId: action.payload.nodeId,
                            checked: action.payload.checked,
                        },
                        checkedFile: {},
                        fileData:{},
                    }
                }
            }
        case CHECK_BELASTUNGSFALL_FILE:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                        checkedFile: {
                            nodeId: action.payload.nodeId,
                            label: action.payload.label,
                            checked: action.payload.checked,
                        }
                    }
                }
            }
        case CHECK_BELASTUNGSFALL_DATA:
            return {
                ...state,
                belastungsfall: {
                    ...state.belastungsfall,
                    [action.payload.belastungsfallId]: {
                        ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                        checkedData: {
                            ...state.belastungsfall[action.payload.belastungsfallId].checkedData,
                            [action.payload.nodeId]: {
                                checked: action.payload.checked,
                            }
                        }
                    }
                }
            }
       case SET_SLICES_BW:
           return {
               loading: false,
               ...state,
               belastungsfall: {
                   ...state.belastungsfall,
                   [action.payload.belastungsfallId]: {
                       ...state.belastungsfall[action.payload.belastungsfallId], // copy old state at this belastungsfall
                       slicesBW: action.payload.slicesBW,
                   }
               }
           }
       case DELETE_BELASTUNGSFALL_DATA:
           var belastungsfallStateCopy = {...state.belastungsfall};
           delete belastungsfallStateCopy[action.payload.belastungsfallId]
           return {
               ...state,
               belastungsfall: {
                   ...belastungsfallStateCopy
               }
           }
       default: return state
    }
}
// link to the rootReducer
export default reducer
