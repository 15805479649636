import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React from "react";
import {Colors} from "../../../../util/colors";
import TreeItem from "@mui/lab/TreeItem";
import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding:  theme.spacing(1),
        backgroundColor: Colors.background,
    },
}));
TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

function TransitionComponent(props) {
    const style = useStyles({
        opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    });

    return (
            <Collapse {...props} />
    );
}


export const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 28,
        marginTop: 0,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },

}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent}/>);

