export const INITIAL_JOINT_ID = 0;
export const INITIAL_ACTIVITY_ID = 0;
export const INITIAL_PARAMETER_ID = 0;
export const INITIAL_PATIENT_ID = 0;
export const INITIAL_FILE_ID = '0';
export const INITIAL_GELENK_NAME = 'Please Select';
export const INITIAL_ZERO = 0;

export const GELENK_SELECTION = [
    {
        reqId: 2355,
        name:'Hip Joint III',
    },
    // {
    //     reqId: 1322,
    //     name: 'Knee Joint',
    // },
    // {
    //     reqId: 864,
    //     name: 'Shoulder Joint',
    // },
    // {
    //     reqId: 648,
    //     name: 'Vertebral Body Replacer',
    // },
    // {
    //     reqId: 12,
    //     name: 'Hip Joint',
    // },
    // {
    //     reqId: 1309,
    //     name: 'Internal Spinal Fixator'
    // },
    {
        reqId: INITIAL_JOINT_ID,
        name: INITIAL_GELENK_NAME
    },
];

export const GELENK_SELECTION_MAP = {
    'Hip Joint III': {
        reqId: 2355,
    },
    // 'Knee Joint': {
    //     reqId: 1322,
    //
    // },
    // 'Shoulder Joint': {
    //     reqId: 864,
    //
    // },
    // 'Vertebral Body Replacer': {
    //     reqId: 648,
    //
    // },
    // 'Hip Joint': {
    //     reqId: 12,
    //
    // },
    // 'Internal Spinal Fixator': {
    //     reqId: 1309,
    //
    // },
    INITIAL_GELENK_NAME: {
        reqId: INITIAL_JOINT_ID,

    },
};

export const AKF_FILE_ENDING = '.akf';
export const EOF_FILE_ENDING = '.eof';
export const IOF_FILE_ENDING = '.iof';
export const COF_FILE_ENDING = '.cof';
export const MP4_FILE_ENDING = '.mp4';
export const AVI_FILE_ENDING = '.avi';
export const WMV_FILE_ENDING = '.wmv';
export const PNG_FILE_ENDING = '.png';
export const UNKOWN_FILE_ENDING = 'UNKOWN_FILE_ENDING';
export const FILE_TYPE_AVI = 'AVI';
export const FILE_TYPE_AKF = 'AKF';
export const FILE_TYPE_EOF = 'EOF';
export const FILE_TYPE_IOF = 'IOF';
export const FILE_TYPE_COF = 'COF';
export const FILE_TYPE_UNKOWN = 'UNKOWN';
export const DATA_INCLUDED_IN = 'Data included in';

export const ACTIVITY_FILTER_LIST =[
    'Standing;',
]

export const BW_1ST_CHART_LABEL = "%BW";
export const NEWTON_1ST_CHART_LABEL = "Contact Force [N]";
export const NEWTON_1ST_CHART_LABEL_SHORT = "Newton";
export const BW_2ND_CHART_LABEL = "%BW*m (dashed line)";
export const NEWTON_2ND_CHART_LABEL = "Momentum [Nm]";

export const ORTHOLOAD_LOGO_URL = 'header-orthoload.png';

export const drawerWidth = '20vw';

