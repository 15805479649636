import {
    FETCH_PARAMETER_PATIENT_REQUEST,
    FETCH_PARAMETER_PATIENT_SUCCESS,
    FETCH_PARAMETER_PATIENT_FAILURE,
} from "./ParameterPatient.type";

const initialState = {
    loading: false,
    parameterPatients: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PARAMETER_PATIENT_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_PARAMETER_PATIENT_SUCCESS:
            const parameterId = action.payload.parameterId;
            const parameterPatient = action.payload.data;
            return {
                loading: false,
                parameterPatient: {
                    ...state.parameterPatient,
                    [parameterId]: {
                        ...parameterPatient
                    },
                },
                error: ''
            }
        case FETCH_PARAMETER_PATIENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
