import axios from 'axios';
import {
    FETCH_PARAMETER_PATIENT_FAILURE,
    FETCH_PARAMETER_PATIENT_SUCCESS,
    FETCH_PARAMETER_PATIENT_REQUEST,
} from "./ParameterPatient.type";
import {BASE_URL, PATIENTS} from "../../util/urls";
import {config} from "../../config/axiosConfig";

const requestURL = (implantId,
                    activityId,
                    activityIndentationLevel,
                    parameterId,
                    parameterIndentationLevel) => {
    return BASE_URL+PATIENTS+`implantId=${implantId}&activityId=${activityId}&activityIndentationLevel=${activityIndentationLevel}&parameterId=${parameterId}&parameterIndentationLevel=${parameterIndentationLevel}`;
}

export const fetchParameterPatients = (implantId,
                                       activityId,
                                       activityIndentationLevel,
                                       parameterId,
                                       parameterIndentationLevel) => {
    // wenn bealstungfall schon im store, dann nicht noch einen Api call
    return (dispatch) => {
        // set loading state
        dispatch(fetchParameterPatientsRequest())
        axios.get(requestURL(   implantId,
                                activityId,
                                activityIndentationLevel,
                                parameterId,
                                parameterIndentationLevel), config)
            .then(response => {
                const data = response.data
                dispatch(fetchParameterPatientsSuccess(data.data, parameterId))
            })
            .catch(error => {
                dispatch(fetchParameterPatientsFailure(error.message))
            })
    };
}

export const fetchParameterPatientsRequest = () => {
    return{
        type: FETCH_PARAMETER_PATIENT_REQUEST
    }
}
export const fetchParameterPatientsFailure = (error) => {
    return {
        type: FETCH_PARAMETER_PATIENT_FAILURE,
        payload: error
    }
}
export const fetchParameterPatientsSuccess = (data, parameterId) => {
    return{
        type: FETCH_PARAMETER_PATIENT_SUCCESS,
        payload: {data, parameterId}
    }
}
