import {
    SAVE_VECTOR_SCENE,
    DELETE_VECTOR_SCENE
} from "./Vector.type";

const initialState = {
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_VECTOR_SCENE:
            return {
                ...state,
                [action.payload.belastungsfallId]: {
                    vectorScene: action.payload.vectorScene,
                }
            }
        case DELETE_VECTOR_SCENE:
            var stateCopy = {...state};
            delete stateCopy[action.payload.belastungsfallId]
            return {
                ...stateCopy
            }
        default:
            return state
    }
}
export default reducer;
