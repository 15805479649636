import {
    SAVE_VECTOR_SCENE,
    DELETE_VECTOR_SCENE
} from "./Vector.type";

export const saveVectorScene = (belastungsfallId, vectorScene) => {
    return {
        type: SAVE_VECTOR_SCENE,
        payload: { belastungsfallId, vectorScene }
    }
}

export const deleteVectorScene = (belastungsfallId) => {
    return {
        type: DELETE_VECTOR_SCENE,
        payload: { belastungsfallId }
    }
}
