import React from "react";

export class ChartDataStore extends React.Component {
    constructor() {
        super();
        this.state = {
            slices: [],
            slicesBW: [],
            activeSlices: [],
            slicesUsr: [],
            translation: [0, 0],
        };
    }
}
