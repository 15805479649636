import {
    CHECK_MEASUREMENTS,
    INITIALIZE_MEASUREMENTS,
    DELETE_MEASUREMENTS
} from "./Measurements.type";

const initialState = {
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_MEASUREMENTS:
            return {
                ...state,
                [action.payload.belastungsfallId]: {
                    ...state[action.payload.belastungsfallId],
                    [action.payload.name]: action.payload.checked
                }
            }
        case INITIALIZE_MEASUREMENTS:
            return {
                ...state,
                [action.payload.belastungsfallId]: action.payload.measurement
            }
        case DELETE_MEASUREMENTS:
            var stateCopy = {...state};
            delete stateCopy[action.payload.belastungsfallId]
            return {
                ...stateCopy
            }

        default: return state
    }
}
// link to the rootReducer
export default reducer
