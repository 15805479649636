import React, {useEffect, useState} from 'react';
import withStyles from '@mui/styles/withStyles';
import Switch from '@mui/material/Switch';
import {Colors} from "../../../util/colors";
import {connect} from "react-redux";





export function ColorSwitch(props){

    const { color,
            belastungsfallId,
            name,
            inputProps,
            measurements,
            switchMeasurement} = props;

    const [jsxContent, setJsxContent] = useState()

    useEffect(()=>{
        const renderSwitch = () => {
            switch(color){
                case Colors.Chart.black:
                    setJsxContent(
                        <BlackSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                case Colors.Chart.red:
                    setJsxContent(
                        <RedSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                case Colors.Chart.green:
                    setJsxContent(
                        <GreenSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                case Colors.Chart.blue:
                    setJsxContent(
                        <BlueSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />

                    )
                    break
                case Colors.Chart.lightblack:
                    setJsxContent(
                        <LightBlackSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                case Colors.Chart.lightblue:
                    setJsxContent(
                        <LightBlueSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                case Colors.Chart.lightgreen:
                    setJsxContent(
                        <LightGreenSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                case Colors.Chart.lightred:
                    setJsxContent(
                        <LightRedSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
                    break
                default:
                    setJsxContent(
                        <BlackSwitch
                            size={"small"}
                            checked={measurements[belastungsfallId][name]}
                            onChange={(e) => handleChange(e)}
                            name={name}
                            inputProps={inputProps}
                        />
                    )
            }
        }
        if(measurements && switchMeasurement){
            renderSwitch()
        }
    },[measurements, switchMeasurement])

    const handleChange = (event) => {
        switchMeasurement(belastungsfallId, event.target.name, event.target.checked);
    };

    const BlackSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.black,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.black,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const GreenSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.green,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.green,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const BlueSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.blue,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.blue,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const RedSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.red,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.red,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const LightBlackSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.lightblack,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.lightblack,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const LightGreenSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.lightgreen,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.lightgreen,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const LightBlueSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.lightblue,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.lightblue,
            },
        },
        checked: {},
        track: {},
    })(Switch);

    const LightRedSwitch = withStyles({
        switchBase: {
            color: Colors.background,
            '&$checked': {
                color: Colors.Chart.lightred,
            },
            '&$checked + $track': {
                backgroundColor: Colors.Chart.lightred,
            },
        },
        checked: {},
        track: {},
    })(Switch);


    return jsxContent ? jsxContent : null
}

const mapStateToProps= (state)=>{
    return{
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ColorSwitch);


