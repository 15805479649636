import {
    FETCH_IMPLANT_ACTIVITY_FAILURE,
    FETCH_IMPLANT_ACTIVITY_SUCCESS,
    FETCH_IMPLANT_ACTIVITY_REQUEST,
} from "./ImplantActivity.type";

const initialState = {
    loading: false,
    implantActivity: {},
    error: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_IMPLANT_ACTIVITY_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_IMPLANT_ACTIVITY_SUCCESS:
            const implantId = action.payload.implantId;
            const implantActivity = action.payload.data;
            return {
                ...state,
                loading: false,
                implantActivity: {
                    ...state.implantActivity,
                    [implantId]: {
                        ...implantActivity
                    },
                },
                error:''
            }
        case FETCH_IMPLANT_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
