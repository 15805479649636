import * as d3 from "d3";
import {d3ChartColor} from "../../../util/colors";
import {ChartBaseDimensions, ChartDimensions, DynamicChartBaseDimensions} from "../../../util/dimensions";
import {check_usr_input} from "./ChartControllerUtil";
import {ORTHOLOAD_LOGO_URL} from "../../../util/constants";


export function createCircleCursors(mouse, chartDataStore){
    let id = 0;
    function ids() {
        if (id < 8) {
            return chartDataStore.state.slices[id++].id;
        }
    }
    let cursor =  mouse.selectAll('.circle-cursors') //this was: mouse-per-line
            .data(chartDataStore.state.activeSlices)
            .enter()
            .append('g')
            .attr('id', ids)
            .attr('class', 'circle-cursors');
    cursor.append('circle')
        .attr('r', 7)
        .style('stroke', function (d) {
            return d3ChartColor(d.id);
        })
        .style('fill', 'none')
        .style('stroke-width', '1px')
        .style('opacity', '0')
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px");
    cursor.append('text')
        .attr('transform', 'translate(10,3)')
        .style('fill', 'black')
        .style('font-size', '16px');    //Font size tooltip text
}

export function createMouseCursorWithTooltip(chartRef, measurements){
    let mouse = d3.select(chartRef.current).select('svg').append('g')
        .attr('transform', 'translate(' + ChartBaseDimensions.chart.margin.left + ',' + ChartBaseDimensions.chart.margin.top + ')')
        .attr('class', 'mouse-over-effects');

    mouse.append('rect') // append a rect to catch mouse movements on canvas
        .attr('width', ChartDimensions.chartWidth)
        .attr('height', ChartDimensions.chartHeight)
        .attr('fill', 'none')
        .attr('pointer-events', 'all');

    mouse
        // on mouseover, show circles and tooltip
        .on('mouseover', function () {
            check_usr_input(measurements);
        })
        // on mouseout, hide circles and tooltip
        .on('mouseout', function () {
            d3.select(chartRef.current).selectAll('.circle-cursors circle')
                .style('opacity', '0');
            d3.select(chartRef.current).selectAll('.circle-cursors text')
                .style('opacity', '0');
        })

    return mouse
}

export function initializeDataGraph(chartRef, belastungsfallId){
    const dataGraph = d3.select(chartRef.current).append('svg')
        .attr('class', `focus${belastungsfallId}`) //brush/zoom related
        .attr('viewBox', '0 0 1000 500')
        .attr('preserveAspectRatio', 'xMinYMin')
        .append('g')
        .attr('transform', 'translate(' + ChartBaseDimensions.chart.margin.left + ',' + ChartBaseDimensions.chart.margin.top + ')');

    //clip drawable space
    dataGraph.append('clipPath')
        .attr('id', 'clip')
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', ChartDimensions.chartWidth)
        .attr('height', ChartDimensions.chartHeight);
    dataGraph.append('svg:image')
        .attr('xlink:href', ORTHOLOAD_LOGO_URL)
        .attr('x', ChartDimensions.ortholoadLogo.xPosition)
        .attr('y', ChartDimensions.ortholoadLogo.yPosition)
        .attr('height', ChartBaseDimensions.ortholoadLogo.height)
        .attr('opacity', 0.3)
    return dataGraph
}

export function initializeContext(chartRef, brush){

    const context = d3.select(chartRef.current).select('svg').append('g')
        .attr('class', 'context')
        .attr('transform', 'translate(' + ChartBaseDimensions.brushChart.margin.left + ','
            + ChartBaseDimensions.brushChart.margin.top + ')');

    context.append('g')
        .attr('class', 'x axisbr')
        .attr('transform', 'translate(0,' + ChartDimensions.brushChartHeight + ')')

    context.append('g')
        .attr('class', 'brush')
        .call(brush)
        .call(brush.move, DynamicChartBaseDimensions.xScale.range());

    return context;
}

export function initializeZoom(){

}
