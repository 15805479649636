import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useState} from "react";
import {StyledTreeItem} from "../common/TreeSelectionItem";import Typography from "@mui/material/Typography";
import * as _ from "lodash";
import {
    checkBelastungsfallPatientFile,
    setBelastungsfallMetaDataFileName,
    fetchFileDatas,
    fetchDataPathData,
    fetchDataPathURL,
} from "../../../../redux";
import { isDataFileName } from '../../../../util/helpingFunctions'
import {connect} from "react-redux";


function FileTreeSelectItem(props) {

    const { nodeId,
            itemName,
            belastungsfallId,
            children,
            belastungsfall,
            checkBelastungsfallPatientFile,
            setBelastungsfallMetaDataFileName,
            fetchDataPathData,
            fetchDataPathURL,
            fetchFileDatas,
            fileData,
            dataPathData,
            loading} = props;


    let bfID = belastungsfall[belastungsfallId];
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        // update checked
        if(bfID
            && bfID.checkedFile
            && bfID.checkedFile.nodeId === nodeId){

            setChecked(bfID.checkedFile.checked)
        }else{
            setChecked(false);
        }
        // update dataPathData
    }, [bfID])




    useEffect(()=>{
        if (checked === true
            && fileData
            && fileData[nodeId]
            && fileData[nodeId].data
            && (!dataPathData || (dataPathData && !dataPathData[nodeId]))) {
            _.uniqBy(fileData[nodeId].data,'path').forEach(item => {
                // only for data-files, no videos or images
                if(isDataFileName(item.name)) {
                    fetchDataPathData(item.path, item.name, nodeId)
                }else{
                    // just save the url for convenience
                    fetchDataPathURL(item.path, item.name, nodeId)
                }
            })

        }
    },[fileData,checked])
    const handleCheckboxClicked = event => {
        checkBelastungsfallPatientFile(nodeId, itemName, belastungsfallId, event.target.checked)
        setBelastungsfallMetaDataFileName(belastungsfallId, itemName)
        // now fetch the parameters of the activity.
        const implantId                 = bfID.implantActivity.implantId;
        const activityId                = bfID.checkedActivity.nodeId;
        const activityIndentationLevel  = bfID.checkedActivity.indentationlevel;
        const parameterId               = bfID.checkedParameter.nodeId;
        const parameterIndentationLevel = bfID.checkedParameter.indentationlevel;
        const patientId                 = bfID.checkedPatient.nodeId;
        const fileId                    = nodeId;

        fetchFileDatas(  implantId,
                         activityId,
                         activityIndentationLevel,
                         parameterId,
                         parameterIndentationLevel,
                         patientId,
                         fileId);
    };

    const checkboxLabel = (check)=>{
        return(
            <div style={{ display: 'flex', alignItems: 'center' , justifyContent : 'flex-start'}}>
                <Typography>
                    <Checkbox
                        data-testid={`file-checkbox-${nodeId}-${belastungsfallId}`}
                        id={nodeId}
                        color="primary"
                        onClick={e => handleCheckboxClicked(e)}
                        checked={check}
                    />
                    {itemName}
                </Typography>
            </div>
        )
    }

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={checkboxLabel(checked)}
        >
            {children}
        </StyledTreeItem>
    )
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        fileData: state.fileDatas.fileData,
        dataPathData: state.fileDatas.dataPathData,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        checkBelastungsfallPatientFile:(nodeId, label, belastungsfallId, checked) =>
                dispatch(checkBelastungsfallPatientFile(nodeId, label, belastungsfallId, checked)),
        fetchFileDatas:( implantId, activityId, activityIndentationLevel, parameterId,
                         parameterIndentationLevel, patientId, fileId) =>
                dispatch(fetchFileDatas(implantId, activityId, activityIndentationLevel, parameterId,
                    parameterIndentationLevel, patientId, fileId)),
        setBelastungsfallMetaDataFileName: (belastungsfallId, itemName)=>
            dispatch(setBelastungsfallMetaDataFileName(belastungsfallId, itemName)),
        fetchDataPathData: (filepath, filePathId, fileId) =>
            dispatch(fetchDataPathData(filepath, filePathId, fileId)),
        fetchDataPathURL: (filepath, filePathId, fileId) =>
            dispatch(fetchDataPathURL(filepath, filePathId, fileId))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileTreeSelectItem)
