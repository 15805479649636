import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import {StyledTreeItem} from "../common/TreeSelectionItem";
import Typography from "@mui/material/Typography";
import {
    checkBelastungsfallImplantActivity,
    fetchActivityParameters,
    setBelastungsfallMetaDataActivityName
} from "../../../../redux";
import {connect} from "react-redux";

function ActivityTreeSelectItem({   nodeId,
                                    itemName,
                                    implantId,
                                    indentationlevel,
                                    belastungsfallId,
                                    children,
                                    belastungsfall,
                                    checkBelastungsfallImplantActivity,
                                    setBelastungsfallMetaDataActivityName,
                                    fetchActivityParameters}) {

    let bfID = belastungsfall[belastungsfallId];
    const [checked, setChecked] = useState(false);
    useEffect(()=>{
        if(bfID && bfID.checkedActivity
            && bfID.checkedActivity.nodeId === nodeId){
            setChecked(bfID.checkedActivity.checked)
        }else{
            setChecked(false);
        }
    }, [belastungsfall])

    const handleCheckboxClicked = event => {
        setBelastungsfallMetaDataActivityName(belastungsfallId, itemName)
        checkBelastungsfallImplantActivity(nodeId, itemName, indentationlevel, event.target.checked, belastungsfallId)
        // now fetch the parameters of the activity.
        const activityId = nodeId; // all nodeIds are activtyIds here
        const activityIndentationLevel = indentationlevel;
        fetchActivityParameters(implantId,activityId, activityIndentationLevel)
    };

    const checkboxLabel = (check)=>{
        return(
        <div>
            <Typography>
                <Checkbox
                    data-testid={`activity-checkbox-${nodeId}-${belastungsfallId}`}
                    id={nodeId}
                    color="primary"
                    onClick={e => handleCheckboxClicked(e)}
                    checked={check}
                />
                {itemName}
            </Typography>
        </div>
        )
    }

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={checkboxLabel(checked)}
            indentationlevel={indentationlevel}
        >
            {children}
        </StyledTreeItem>
    )
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        checkBelastungsfallImplantActivity: (nodeId,label,indentationlevel,checked, belastungsfallId) =>
                dispatch(checkBelastungsfallImplantActivity(nodeId,label,indentationlevel,checked, belastungsfallId)),
        setBelastungsfallMetaDataActivityName:(newBelastungsfallId, activityName) =>
            dispatch(setBelastungsfallMetaDataActivityName(newBelastungsfallId, activityName)),
        fetchActivityParameters:(implantId,activityId,activityIndentationLevel) =>
            dispatch(fetchActivityParameters(implantId, activityId, activityIndentationLevel)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityTreeSelectItem)
