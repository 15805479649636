export const ADD_CASE_STUDY='ADD_CASE_STUDY';
export const OPEN_CASE_STUDY_ACCORDION='OPEN_CASE_STUDY_ACCORDION';
export const SELECT_CASE_STUDY_FROM_LIST='SELECT_CASE_STUDY_FROM_LIST';
export const OPEN_ACTIVITY_ACCORDION='OPEN_ACTIVITY_ACCORDION';
export const SELECT_THE_FIRST_BOX_WITHOUT_TREE_EXPANDED='SELECT_THE_FIRST_BOX_WITHOUT_TREE_EXPANDED';
export const OPEN_PARAMTER_ACCORDION='OPEN_PARAMTER_ACCORDION';
export const SELECT_ANY_PARAMETER='SELECT_ANY_PARAMETER';
export const OPEN_PATIENT_ACCORDION='OPEN_PATIENT_ACCORDION';
export const SELECT_ANY_PATIENT='SELECT_ANY_PATIENT';
export const OPEN_FILE_ACCORDION='OPEN_FILE_ACCORDION';
export const OPEN_DATA_ACCORDION='OPEN_DATA_ACCORDION';
export const SELECT_ANY_FILE='SELECT_ANY_FILE';
export const SELECT_ANY_DATA='SELECT_ANY_DATA';
export const COMPARE_DATA='COMPARE_DATA';
export const INTRODUCTION='INTRODUCTION';
export const DRAWER_DESCRIPTION_NEW_CASE_STUDY='DRAWER_DESCRIPTION_NEW_CASE_STUDY';
export const DESCRIPTION_CASE_STUDY1='DESCRIPTION_CASE_STUDY1';
export const DESCRIPTION_CASE_STUDY2='DESCRIPTION_CASE_STUDY2';
export const DESCRIPTION_ACITIVITY='DESCRIPTION_ACITIVITY';
export const CONTINUE_TO_SITE='CONTINUE_TO_SITE';

