import React, { useEffect, useState} from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ParameterTreeSelectItem from './ParameterTreeSelectItem';
import OrtholoadAccordion from "../../layout/OrtholoadAccordion";
import {StyledTreeItem, useStyles} from '../common/TreeSelectionItem'
import {useIconToggleClick} from "useseparatetoggleclick";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {TwoDimHashMap} from "../../../../util/hashmap";
import {
    INITIAL_ACTIVITY_ID,
    INITIAL_ZERO
} from '../../../../util/constants'
import {
    fetchActivityParametersOfBelastungsfall,
    fetchActivityParameters
} from "../../../../redux";

ParameterTreeSelect.propTypes = {
    customClasses: PropTypes.any,
};

function ParameterTreeSelect(props) {

    const { customClasses,
        belastungsfall,
        belastungsfallId,
        belastungsfallMetaData,
        activityParameter,
        fetchActivityParameters,
        fetchActivityParametersOfBelastungsfall,
        loading } = props;

    let classes = useStyles();
    const bfID = belastungsfall[belastungsfallId];
    const [JSXComponents, setJSXComponents] = useState({})
    const [activityId, setActivityId] = useState(INITIAL_ACTIVITY_ID);


    if(customClasses){
        classes = customClasses;
    }

    useEffect(()=>{
        if(bfID
            && bfID.checkedActivity
            && bfID.checkedActivity.nodeId !== activityId){
            setActivityId(bfID.checkedActivity.nodeId)
        }
    },[bfID])

    useEffect(()=>{
        if(bfID
            && bfID.checkedActivity) {

            fetchParemeterTreeSelectData(bfID.checkedActivity.nodeId)
        }
    },[activityId])

    useEffect(()=>{
        if(activityParameter && activityParameter[activityId]){
            fetchActivityParametersOfBelastungsfall(belastungsfallId, activityId)
        }
    },[activityParameter])

    useEffect(()=>{

        if(activityParameter
            && activityParameter[activityId]
            && bfID.checkedActivity.checked === true) {

            const components = {}
            components[INITIAL_ZERO] = {id: 0, name: 'root', indentationlevel:-10, parentId: 0}
            var hashMap2d = new TwoDimHashMap();
            Object.values(activityParameter[activityId]).flat().forEach(item => {
                hashMap2d.set(item.parentId, item.id, (
                    <StyledTreeItem
                        key={item.id}
                        nodeId={item.id}
                        indentationlevel={item.indentaionLevel}
                        label={item.label}
                    />
                ))
                // make components
                components[item.id] = {
                    id: item.id,
                    name: item.label,
                    indentationlevel: item.indentaionLevel,
                    parentId: item.parentId
                }
            });


            const jsxComponents = {}
            jsxComponents[INITIAL_ZERO] = (
                <StyledTreeItem
                    key={0}
                    nodeId={0}
                    indentationlevel={-10}
                    label={'Root'}
                />
            )

            Object.keys(hashMap2d.hashMap).reverse().forEach(parentId => {
                let children = Object.keys(hashMap2d.hashMap[parentId].hashMap).map(childId => {
                    return hashMap2d.get(parentId, childId);
                })
                jsxComponents[parentId] = (
                    <StyledTreeItem
                        key={parentId}
                        nodeId={parentId}
                        label={components[parentId].name}
                        indentationlevel={components[parentId].indentationLevel}
                        children={children}
                    />
                )
            })
            setJSXComponents(jsxComponents)
        }else{
            setJSXComponents(undefined);
        }
    },[activityParameter,activityId])

    const fetchParemeterTreeSelectData = (activityId) => {
        // setActivityId(activityId)
        if(activityId !== INITIAL_ACTIVITY_ID && loading !== true
            && bfID.implantActivity
            && bfID.implantActivity.implantId){

            fetchActivityParameters(bfID.implantActivity.implantId,
                                    activityId,
                                    bfID.checkedActivity.indentationlevel)
        }
    }

    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();

    const getTreeItemsJSXComponents = (parentComponents) => {
        return parentComponents.map( child => {
            let children = undefined;
            if (JSXComponents[child.key]
                && JSXComponents[child.key].props.children
                && JSXComponents[child.key].props.children.length > 0) {
                children = getTreeItemsJSXComponents(JSXComponents[child.key].props.children);
            }
            return(
                <ParameterTreeSelectItem
                    key={child.props.nodeId}
                    nodeId={child.props.nodeId.toString()}
                    itemName={child.props.label}
                    belastungsfallId={belastungsfallId}
                    indentationlevel={child.props.indentationlevel}
                    activityId={activityId}
                    children={children}
                />
            )
        });
    };

    return JSXComponents && JSXComponents[0]
            && bfID
            && bfID.checkedActivity
            && bfID.checkedActivity.checked == true?
    (
        <OrtholoadAccordion id="orthoload-accordion-parameter" name={'Parameter'}
                            dataTestID={`${belastungsfallId}-${belastungsfallMetaData[belastungsfallId].name}-parameter-accordion`}>
            <div className={classes.container}>
                <TreeView
                    className={classes.root}
                    defaultExpanded={['1']}
                    expanded={expanded2}
                    onNodeToggle={onIconToggle}
                    defaultCollapseIcon={
                        <IconIconWrapper>
                            <ExpandMoreIcon />
                        </IconIconWrapper>
                    }
                    defaultExpandIcon={
                        <IconIconWrapper>
                            <ChevronRightIcon />
                        </IconIconWrapper>
                    }
                    defaultEndIcon={<MoreHorizIcon />}
                >
                    {getTreeItemsJSXComponents(JSXComponents[0].props.children)}
                </TreeView>
            </div>
        </OrtholoadAccordion>
    ):(null
       );
}
const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        activityParameter: state.activityParameters.activityParameter,
        belastungsfallMetaData: state.belastungsfallMetaData,
        loading: state.belastungsfaelle.loading
    }
}
const mapDispatchToProps = dispatch => {
    return{
        fetchActivityParametersOfBelastungsfall:  (belastungsfallId, implantId) =>
            dispatch(fetchActivityParametersOfBelastungsfall(belastungsfallId, implantId)),
        fetchActivityParameters: (implantId, activityId, indentationlevel) =>
            dispatch(fetchActivityParameters(implantId, activityId, indentationlevel)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParameterTreeSelect)
