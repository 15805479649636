import {
    CHECK_MEASUREMENTS,
    INITIALIZE_MEASUREMENTS,
    DELETE_MEASUREMENTS
} from "./Measurements.type";


export const switchMeasurement = (belastungsfallId, name, checked) => {
    return {
        type: CHECK_MEASUREMENTS,
        payload: { belastungsfallId, name, checked }
    }
}

export const initializeMeasurementSwitches = (belastungsfallId, measurement) => {
    return{
        type: INITIALIZE_MEASUREMENTS,
        payload: { belastungsfallId, measurement }
    }
}
export const deleteMeasurementSwitches = (belastungsfallId) => {
    return{
        type: DELETE_MEASUREMENTS,
        payload: { belastungsfallId }
    }
}


