import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DataPanel from "../../../components/main/layout/DataPanel";
import {
    sortableContainer,
    sortableElement,
    sortableHandle,
    arrayMove } from 'react-sortable-hoc';
import Fab from "@mui/material/Fab";
import {OpenWithRounded} from "@mui/icons-material";
import {Colors} from "../../../util/colors";

function arrayIntersection(array1, array2){
    return array1.filter(value => array2.includes(value));
}

function arrayUnification(array1, array2){
    let unifiedArray = [...array1]
    array2.forEach(value => {
        if(!array1.includes(value)){
            unifiedArray = [...unifiedArray, value]
        }
    });
    return unifiedArray
}

const DragHandle = sortableHandle(() =>
    <span>
        <Fab style={{
            backgroundColor: Colors.error,
            borderRadius: '0%',
            width:'25vw'
        }} >
            <OpenWithRounded
                style={{
                    boxShadow: '5px',
                    color: Colors.secondary,
                }}
                fontSize={'large'}
            />
        </Fab>
    </span>
);

const SortableItem = sortableElement(({value}) =>
    <div>
        {value}
        <div style={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            borderRadius: '0%',
            // width:'80%',
        }}>
            <DragHandle/>
        </div>
    </div>
);

const SortableContainer = sortableContainer(({children}) => {
    return (
        <div style={{display:'flex', alignItems:'stretch'}}>
            {children}
        </div>
    )
});

function DashboardMain({belastungsfall}) {

    const [panelsBelastungsfallIds, setPanelsBelastungsfallId] = useState([])

    useEffect(()=> {
        if(panelsBelastungsfallIds.length !== Object.keys(belastungsfall).length) {
            // add belastungsfallId
            if (panelsBelastungsfallIds.length < Object.keys(belastungsfall).length) {
                setPanelsBelastungsfallId(arrayUnification(panelsBelastungsfallIds, Object.keys(belastungsfall)))
            // remove belastungfallId
            } else if (panelsBelastungsfallIds.length > Object.keys(belastungsfall).length) {
                setPanelsBelastungsfallId(arrayIntersection(panelsBelastungsfallIds, Object.keys(belastungsfall)))
            }
        }
    },[belastungsfall])

    const onSortEnd = ({oldIndex, newIndex}) => {
        setPanelsBelastungsfallId( arrayMove(panelsBelastungsfallIds, oldIndex, newIndex))
    };

    return (
        <SortableContainer
            transitionDuration={300}
            useWindowAsScrollContainer={true}
            axis={'xy'}
            onSortEnd={onSortEnd}
            useDragHandle={true}>
            {panelsBelastungsfallIds.map((belastungsfallId, index) => (
                <SortableItem key={`item-${index}`} index={index} value={
                    <DataPanel belastungsfallId={belastungsfallId} key={belastungsfallId}/>
                } />
            ))}
        </SortableContainer>

    );
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}
const mapDispatchToProps = dispatch => {
    return{
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardMain)
