import {
    FETCH_SEARCH_REQUEST,
    FETCH_SEARCH_SUCCESS,
    FETCH_SEARCH_FAILURE
} from "./FileSearch.type";

const initialState = {
    loading: false,
    searchResult: [],
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEARCH_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_SEARCH_SUCCESS:
            return {
                loading: false,
                searchResult: action.payload.data,
                error: ''
            }
        case FETCH_SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
