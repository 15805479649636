import {
    FETCH_ACTIVITY_PARAMETER_REQUEST,
    FETCH_ACTIVITY_PARAMETER_SUCCESS,
    FETCH_ACTIVITY_PARAMETER_FAILURE,
} from "./ActivityParameter.type";

const initialState = {
    loading: false,
    activityParameter: {},
    error: '',
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITY_PARAMETER_REQUEST:
            return {
                ...state,
                loading: true,
            }
       case FETCH_ACTIVITY_PARAMETER_SUCCESS:
           const activityId = action.payload.activityId;
           const activityParameter = [action.payload.data];
            return {
                loading: false,
                activityParameter: {
                    ...state.activityParameter,
                    [activityId]: {
                        ...activityParameter
                    },
                },
                error:''
            }
       case FETCH_ACTIVITY_PARAMETER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
       default: return state
    }
}
// link to the rootReducer
export default reducer
