import React, {useEffect, useRef, useState} from 'react';
import {
    setSlicesBW,
    initializeMeasurementSwitches
} from '../../../redux';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as d3 from 'd3';
import ErrorBoundary from "../../../error/error-boundary";
import makeStyles from '@mui/styles/makeStyles';
import {Colors, d3ChartColor} from "../../../util/colors";
import {
    DynamicChartBaseDimensions,
    ChartBaseDimensions,
    ChartDimensions,
    Dimensions
} from "../../../util/dimensions";
import {ChartTooltip} from "./ChartTooltip";
import {
    constructActiveSlices,
    updateTopChartContainer,
    updateBushDomain,
    addMouseOverChartListener,
    updateGirdVisibilityControl,
    updateTopGraphChartObject,
    updateBrushSVG,
    updateLabelUnitControl,
    initializeChartContainer,
} from "./ChartControllerUtil";
import {
    createCircleCursors,
    createMouseCursorWithTooltip,
    initializeDataGraph,
} from "./ChartGraphicsUtil";
import { parseData } from "./ChartDataUtil";
import {ChartDataStore} from "./ChartDataStore";
import {Chip, Stack} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    chart:{
        width: '100%',
        padding:  theme.spacing(3),
        backgroundColor: Colors.background,
    },
    container: {
        width: '100%',
        backgroundColor: Colors.primaryV2,
        borderRadius: Dimensions.borderRadius,
    },
    chartHeader:{
        display: 'flex',
    },
    chartTitle:{
        flex: 5,
        paddingLeft: theme.spacing(5),
        color: Colors.background,
        textAlign: 'left'
    },
}));

function ChartD3(props){

    const {
        belastungsfallId,
        data,
        title,
        measurements,
        vectorScenes,
        belastungsfallMetaData,
        setSlicesBW,
        initializeMeasurementSwitches,
        belastungsfall
    } = props;

    const classes = useStyles();
    const chartRef = useRef();
    const chartDataStore = new ChartDataStore();
    let bFFileId = useState(`${belastungsfall[belastungsfallId].fileData.fileId}`)
    const [prevBFFileId, setPrevBFFileId] = useState('');

    const updateBelastungsfallFileId = () =>{
        setPrevBFFileId(bFFileId)
    }

    //line constructor
    const lineF = d3.line()
        .x(d => DynamicChartBaseDimensions.xScale(d.time))
        .y(d => DynamicChartBaseDimensions.leftYAxisScale(d.measurement));

    const lineM = d3.line()
        .x(d => DynamicChartBaseDimensions.xScale(d.time))
        .y(d => DynamicChartBaseDimensions.rightYAxisScale(d.measurement));

    const lineBrush = d3.line()
        .x(d => DynamicChartBaseDimensions.brushChartXScale(d.time))
        .y(d => DynamicChartBaseDimensions.brushChartYScale(d.measurement));

    //color scheme
    d3ChartColor.domain(function (d) {
        return d.id;
    });

    function cleanup(){
        d3.selectAll(`.focus${belastungsfallId}`).remove();
    }

    function initializeChartWithMeasurementSwitches(){
        var measurementSwitches = {}
        chartDataStore.state.translation = 1; //global var  -> resets zoom

        if (measurements[belastungsfallId] === undefined) {
            for (let i = 0; i < chartDataStore.state.slicesBW.length; i++) {
                let key = chartDataStore.state.slicesBW[i].id
                measurementSwitches[key] = true
            }
            measurementSwitches['tGrid'] = true
            measurementSwitches['tUnit'] = false

            initializeMeasurementSwitches(belastungsfallId, measurementSwitches)
            constructActiveSlices(measurementSwitches, chartDataStore)
        }else{
            constructActiveSlices(measurements[belastungsfallId], chartDataStore)
        }
    }


    useEffect(() => {
        updateBelastungsfallFileId()
        if(data && data.length > 0) {

            cleanup()
            const zoom = d3.zoom()
                .scaleExtent([1, 20])
                //prevents lines from going out of bounds
                .translateExtent([[0, 0], [ChartDimensions.chartWidth, ChartDimensions.chartHeight]])
                .extent([[0, 0], [ChartDimensions.chartWidth, ChartDimensions.chartHeight]])

            //init brush
            const brush = d3.brushX()
                .extent([[0, 0], [ChartDimensions.chartWidth, ChartDimensions.brushChartHeight]])
                .on('brush end', () => {
                    let s = d3.event.selection || DynamicChartBaseDimensions.brushChartXScale.range();
                    DynamicChartBaseDimensions.xScale.domain(s.map(DynamicChartBaseDimensions.brushChartXScale.invert,
                        DynamicChartBaseDimensions.brushChartXScale));
                    brushSVG.selectAll('.brushPath').attr('d', function (d) {
                        return lineBrush(d.values)
                    });
                    chartContainerObject.selectAll('.graphPath').attr('d', function (d) {
                        if (d.id === 'Fx' || d.id === 'Fy' || d.id === 'Fz' || d.id === 'F') {
                            return lineF(d.values);
                        } else {
                            return lineM(d.values);
                        }
                    });

                    brushSVG.select('.zoom').call(zoom.transform, d3.zoomIdentity
                        .scale(ChartDimensions.chartWidth / (s[1] - s[0]))
                        .translate(-s[0], 0));

                });
            // Adding svg to canvas
            const dataGraph = initializeDataGraph(chartRef, belastungsfallId)
            //brush/zoom related
            let chartContainerObject = initializeChartContainer(chartRef, measurements, belastungsfallId)

            //Adding brush svg to canvas
            const brushSVG = d3.select(chartRef.current).select('svg').append('g')
                .attr('class', 'context')
                .attr('transform', 'translate(' + ChartBaseDimensions.brushChart.margin.left + ','
                    + ChartBaseDimensions.brushChart.margin.top + ')');
            brushSVG.append('g')
                .attr('class', 'x axisbr')
                .attr('transform', 'translate(0,' + ChartDimensions.brushChartHeight + ')')
            brushSVG.append('g')
                .attr('class', 'brush')
                .call(brush)
                .call(brush.move, DynamicChartBaseDimensions.xScale.range());

            const mouse = createMouseCursorWithTooltip(chartRef, measurements)
            addMouseOverChartListener(mouse, belastungsfallId, vectorScenes, chartDataStore)
            ////////////////////////////// chart.parseAFKData ///////////////////////////
            parseData(data, chartDataStore)
            if (prevBFFileId[0] !== bFFileId[0]) {
                setSlicesBW(belastungsfallId, chartDataStore.state.slicesBW)
            }
            initializeChartWithMeasurementSwitches()
            ////////////////////////// chart.updateF  ///////////////////////////////
            updateChart(mouse, brushSVG, dataGraph, chartContainerObject)
        }

    }, [data, measurements])


    function updateChart(mouse, brushSVG, dataGraph, chartContainerObject){

        updateTopChartContainer(chartDataStore,chartContainerObject, measurements, belastungsfallId)

        updateBushDomain(chartDataStore,brushSVG)

        createCircleCursors(mouse, chartDataStore);

        updateGirdVisibilityControl(dataGraph, chartDataStore, measurements, belastungsfallId)

        updateLabelUnitControl()

        updateTopGraphChartObject(chartContainerObject,chartDataStore,lineF,lineM)

        updateBrushSVG(brushSVG,chartDataStore,lineBrush)

    }

    function handleClick(){
        console.log("SomethingSomethingInTheMonthOfMay")
    }

    return (
        <ErrorBoundary>
                <div className={classes.container}>
                    <div className={classes.chartHeader}>
                        <div className={classes.chartTitle}>
                            <h3 >
                                <Stack direction="row" spacing={1}>
                                    <Chip label={belastungsfallMetaData[belastungsfallId].name}
                                          color="primary"
                                          onClick={handleClick}/>
                                    <Chip label={belastungsfallMetaData[belastungsfallId].activityName}
                                          color="primary"
                                          variant="outlined"  onClick={handleClick}/>
                                    <Chip label={belastungsfallMetaData[belastungsfallId].parameterName}
                                          color="primary"
                                          variant="outlined"  onClick={handleClick}/>
                                    <Chip label={belastungsfallMetaData[belastungsfallId].fileName}
                                          color="primary"
                                          variant="outlined"  onClick={handleClick}/>
                                </Stack>
                            </h3>
                        </div>
                    <ChartTooltip/>
                    </div>
                    <div className={classes.chart}>
                        <div ref={chartRef}/>
                    </div>
                </div>

        </ErrorBoundary>
    )
}

ChartD3.propTypes = {
    belastungsfallId: PropTypes.string,
    data: PropTypes.string,
    fileType: PropTypes.string
};

const mapStateToProps = state =>{
    return{
        measurements: state.measurements,
        vectorScenes: state.vectorScenes,
        belastungsfallMetaData: state.belastungsfallMetaData,
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        setSlicesBW: (belastungsfallId, slicesBW) => dispatch(setSlicesBW(belastungsfallId, slicesBW)),
        initializeMeasurementSwitches: (belastungsfallId, measurements) => dispatch(initializeMeasurementSwitches(belastungsfallId, measurements))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChartD3)
