import React, { Component } from 'react';
import ChartD3 from './ChartD3.jsx';
import {
    isChartDataReady,
    getFileEnding,
} from "../../../util/helpingFunctions";
import * as _ from 'lodash';
import {connect} from "react-redux";

class Chart extends Component{


    shouldComponentUpdate(nextProps, nextState) {
        return isChartDataReady(nextProps) && !_.isEqual(nextProps, this.props)
    }

    render() {

        let content;

        if (isChartDataReady(this.props)) {

            const fileId = this.props.belastungsfall[this.props.belastungsfallId].fileData.fileId
            const fileEnding = getFileEnding(this.props.dataPathData[fileId]);
            // the chart.js parses the data. We need to pass the information of the file type here
            content = <ChartD3
                belastungsfallId={this.props.belastungsfallId}
                data={this.props.dataPathData[fileId][`${fileId}${fileEnding}`].filePathData}
                title={fileId}
            />;
        }
        return (
            <>
                {content}
            </>
        );
    }
}

const mapStateToProps= (state)=>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        dataPathData: state.fileDatas.dataPathData,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Chart);
