import React from "react";
import PropTypes from "prop-types";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
    switchMeasurement
} from "../../../redux";
import {connect} from "react-redux";
import ErrorBoundary from "../../../error/error-boundary";
import {Colors} from "../../../util/colors";
import {ColorSwitch} from "./ColoredSwitch";
import {BW_1ST_CHART_LABEL, NEWTON_1ST_CHART_LABEL_SHORT} from "../../../util/constants";

Measurements.propTypes = {
    belastungsfall: PropTypes.shape({
        name: PropTypes.string.isRequired,
        number: PropTypes.number
    }),
};

function Measurements(props){

    const { belastungsfallId,
            measurements,
            switchMeasurement } = props;

    const handleChange = (event) => {
        switchMeasurement(belastungsfallId, event.target.name, event.target.checked);
    };
    const switchColors = Object.values(Colors.Chart)

    const renderSwitch = (measurementKey, idx) => {
        if(measurementKey==="tUnit"){
            return (
                <FormControlLabel
                    key={idx}
                    control={
                        <Switch
                            checked={measurements[belastungsfallId][measurementKey]}
                            onChange={handleChange}
                            name="tUnit"
                            color={"primary"}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    }
                    label={`Units: ${measurements[belastungsfallId][measurementKey] ? NEWTON_1ST_CHART_LABEL_SHORT : BW_1ST_CHART_LABEL}`}
                />
            )
        }else if(measurementKey==="tGrid") {
            return (
                <FormControlLabel
                    key={idx}
                    control={
                        <Switch
                            checked={measurements[belastungsfallId][measurementKey]}
                            onChange={handleChange}
                            name="tGrid"
                            color={"primary"}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    }
                    label="Grid Line"
                />
            )
        }else{
            return (
                <FormControlLabel
                    key={idx}
                    control={
                        <ColorSwitch
                            color={switchColors[idx+1]}
                            belastungsfallId={belastungsfallId}
                            name={measurementKey}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            measurements={measurements}
                            switchMeasurement={switchMeasurement}
                        />
                    }
                    label={measurementKey}
                />
            )
        }
    }

    return (measurements[belastungsfallId] ? (
        <ErrorBoundary>
            <div >
                {Object.keys(measurements[belastungsfallId]).map((measurementKey,idx) => {
                    return renderSwitch(measurementKey, idx)
                })}
            </div>
        </ErrorBoundary>):(null)
    )
}

const mapStateToProps= (state)=>{
    return{
        measurements: state.measurements,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        switchMeasurement: (belastungsfallId, name, checked) => dispatch(switchMeasurement(belastungsfallId, name, checked)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Measurements);

