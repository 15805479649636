export class HashMap{
    constructor() {
        this.hashMap = {}
    }
    set(key, object){
        if(this.hashMap[key] === undefined) {
            this.hashMap[key] = new HashMap();
        }
        this.hashMap[key] = object
    }
    get(key){
        return this.hashMap[key]
    }
}

export class TwoDimHashMap {
    constructor() {
        this.hashMap = {}
    }
    set(key1, key2, object){
        if(this.hashMap[key1] === undefined) {
            this.hashMap[key1] = new HashMap();
        }
        this.hashMap[key1].set(key2,object)

    }
    get(key1, key2){
        return this.hashMap[key1].get(key2)
    }
}
