import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    fetchFileDatasOfBelastungsfall,
    fetchFileDatas,
} from "../../../../redux";
import {AVI_FILE_ENDING, INITIAL_FILE_ID} from "../../../../util/constants";
import {StyledTreeItem} from "../common/TreeSelectionItem";
import {useIconToggleClick} from "useseparatetoggleclick";
import DataTreeSelectItem from "../data/DataTreeSelectItem";
import OrtholoadAccordion from "../../layout/OrtholoadAccordion";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from '@mui/material/Button';
import ArchiveIcon from '@mui/icons-material/Archive';
import JSZip from "jszip";
import saveAs from 'file-saver';
import { isDataFileName } from '../../../../util/helpingFunctions'
import makeStyles from '@mui/styles/makeStyles';
import {Colors} from "../../../../util/colors";
import {Dimensions} from "../../../../util/dimensions";

const useStyles = makeStyles((theme) => ({
    root: {
        // flexBasis: '100%',
        height: '100%',
        width: '100%',
        padding:  theme.spacing(1),
        backgroundColor: Colors.background,
    },
    container: {
        backgroundColor: Colors.primaryV2,
        borderRadius: Dimensions.borderRadius,
    },
    download: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2)
    },
    title:{
        fontSize: '1.25rem',
        flexBasis: '100%',
        textAlign: 'center',
        fontWeight: 900,
    }
}));

function DataTreeSelect(props) {

    const { belastungsfall,
            belastungsfallId,
            belastungsfallMetaData,
            fileData,
            dataPathData,
            fetchFileDatasOfBelastungsfall,
            fetchFileDatas,
            loading} = props;

    const classes = useStyles()
    let bfID = belastungsfall[belastungsfallId]
    const [JSXComponents, setJSXComponents] = useState(undefined)
    const [fileId, setFileId] = useState(INITIAL_FILE_ID)

    useEffect(()=>{
        if(bfID
            && bfID.checkedFile
            && bfID.checkedFile.nodeId !== fileId){
            setFileId(bfID.checkedFile.nodeId)
        }
    },[bfID])

    useEffect(()=>{
        if(bfID
            && bfID.checkedFile) {

            fetchDataTreeSelectData(fileId)
        }
    },[fileId])

    useEffect(()=>{
        if(fileData && fileData[fileId]){
            fetchFileDatasOfBelastungsfall(belastungsfallId, fileId)
        }
    },[fileData])

    useEffect(()=>{
        if(fileData
            && fileData[fileId]
            && bfID.checkedFile.checked === true) {

            const jsxComponents = {}
            Object.values(fileData[fileId].data).flat().forEach(item => {
                jsxComponents[item.name] = (
                    <StyledTreeItem
                        key={item.name}
                        nodeId={item.name}
                        label={item.name}
                    />
                )
            });
            setJSXComponents(jsxComponents)
        }else{
            setJSXComponents(undefined);
        }
    },[fileData, fileId])

    const fetchDataTreeSelectData = (fileId) => {
        const belAtId = bfID
        if(fileId !== INITIAL_FILE_ID && loading !== true
            && belAtId.activityParameter
            && belAtId.activityParameter.activityId
            && belAtId.implantActivity
            && belAtId.implantActivity.implantId
            && belAtId.checkedActivity
            && belAtId.checkedActivity.nodeId
            && belAtId.checkedParameter
            && belAtId.checkedParameter.nodeId
            && belAtId.patientFile
            && belAtId.patientFile.patientId){

            const implantId                 = belAtId.implantActivity.implantId;
            const activityId                = belAtId.activityParameter.activityId;
            const activityIndentationLevel  = belAtId.checkedActivity.indentationlevel;
            const parameterId               = belAtId.checkedParameter.nodeId;
            const parameterIndentationLevel = belAtId.checkedParameter.indentationlevel;
            const patientId                 = belAtId.patientFile.patientId;
            fetchFileDatas(  implantId,
                activityId,
                activityIndentationLevel,
                parameterId,
                parameterIndentationLevel,
                patientId,
                fileId)
        }
    }
    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();

    const getTreeItemsJSXComponents = (components) => {
        return Object.values(components).map( component => {
            return(
                <DataTreeSelectItem
                    key={component.props.nodeId}
                    nodeId={component.props.nodeId.toString()}
                    itemName={component.props.label}
                    belastungsfallId={belastungsfallId}

                />
            )
        });
    };

    function toDataURL(url) {
        return new JSZip.external.Promise(function (resolve, reject) {
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function() {
                // you should handle non "200 OK" responses as a failure with reject
                resolve(xhr.response);
            };
            // you should handle failures too
            xhr.open('GET', url);
            xhr.send();
        });
    }

    function downloadSelectedFiles(){
        var zip = new JSZip();
        // get all the keys that are checked in the data section
        const checkedDataOf = bfID.checkedData
        const filePathDatasForDownload = dataPathData[bfID.fileData.fileId];
        // Zip file with the file name.
        Object.keys(checkedDataOf).map(fileName =>{
            if(!isDataFileName(fileName)) {
                const dataBase64 = toDataURL(filePathDatasForDownload[fileName].filePathURL)
                zip.file(fileName, dataBase64, {base64: true})
                // zip.file(image.name, base64String, {base64: true});
            }else {
                zip.file(fileName, filePathDatasForDownload[fileName].filePathData);
            }
        })

        zip.generateAsync({type: "blob"})
            .then(content => {
                saveAs(content, `${bfID.fileData.fileId}.zip`);
        });
    }

    return JSXComponents
    && bfID
    && bfID.checkedActivity
    && bfID.checkedActivity.checked == true?
        (
            <>
                <OrtholoadAccordion id="orthoload-accordion-data" name={'Data'} dataTestID={`${belastungsfallId}-${belastungsfallMetaData[belastungsfallId].name}-data-accordion`}>
                    <div className={classes.container}>
                        <TreeView
                            className={classes.root}
                            defaultExpanded={['1']}
                            expanded={expanded2}
                            onNodeToggle={onIconToggle}
                            defaultCollapseIcon={
                                <IconIconWrapper>
                                    <ExpandMoreIcon />
                                </IconIconWrapper>
                            }
                            defaultExpandIcon={
                                <IconIconWrapper>
                                    <ChevronRightIcon />
                                </IconIconWrapper>
                            }
                            defaultEndIcon={<MoreHorizIcon />}
                        >
                            {JSXComponents ?
                                getTreeItemsJSXComponents(JSXComponents)
                                : ('No Data. Please select File')
                            }

                        </TreeView>
                    </div>
                    <div className={classes.download} >
                        <Button variant={"contained"} onClick={() => downloadSelectedFiles()}>
                            <ArchiveIcon/>
                            Download
                        </Button>
                    </div>
                </OrtholoadAccordion>

            </>

        ):  (
            null
            );
}
const mapStateToProps = state => {
    return {
        belastungsfall: state.belastungsfaelle.belastungsfall,
        belastungsfallMetaData: state.belastungsfallMetaData,
        dataPathData: state.fileDatas.dataPathData,
        fileData: state.fileDatas.fileData,
        loading: state.fileDatas.loading,
    }
}
const mapDispatchToProps = dispatch =>{
    return{
        fetchFileDatasOfBelastungsfall: (belastungsfallId, fileId) =>
            dispatch(fetchFileDatasOfBelastungsfall(belastungsfallId, fileId)),
        fetchFileDatas:  (  implantId,
                            activityId,
                            activityIndentationLevel,
                            parameterId,
                            parameterIndentationLevel,
                            patientId,
                            fileId) =>
            dispatch(fetchFileDatas(implantId,
                activityId,
                activityIndentationLevel,
                parameterId,
                parameterIndentationLevel,
                patientId,
                fileId)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataTreeSelect);
