import React from "react";
import Typography from "@mui/material/Typography";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import ErrorBoundary from "../../../error/error-boundary";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Colors} from "../../../util/colors";
import {Tooltip} from "@mui/material";
import {BW_1ST_CHART_LABEL} from "../../../util/constants";


const useStyles = makeStyles((theme) => ({
    help:{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: Colors.primaryV2,
        color: theme.palette.common.white,
        fontSize: 16,
        marginBottom: theme.spacing(0.1),
        padding: theme.spacing(2),
    },
}));

export function ChartTooltip() {

    const classes = useStyles();

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 620,
            fontSize: theme.typography.pxToRem(16),
            border: '5px solid #dadde9',
        },
    }))(Tooltip);

    return (
        <ErrorBoundary>
            <div className={classes.help}>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Handle the Chart</Typography>
                            <em>{"Use below switches to show"}</em>
                            <ul>
                                <li>Fx, Fy, Fz - the Forces in x-,y- and z-direction</li>
                                <li>Mx, My, Mz - the Momenta in x-,y- and z-direction</li>
                                <li>F and M - Show the total forces and momanta</li>
                                <li>Units: {BW_1ST_CHART_LABEL} - toggles normalized force to body weight versus absolute force in
                                    Newton
                                </li>
                                <li>Grid Line - toggles grid lines</li>
                            </ul>
                            <em>{"Control the time interval of the chart in below mini chart overlayed in grey"}</em>
                            <em>{"Also shift the grey window on the left and right by clicking on the left and right edges"}</em>

                        </React.Fragment>
                    }
                >
                    <HelpOutlineRoundedIcon/>
                </HtmlTooltip>
            </div>
        </ErrorBoundary>
    );
}
