import * as THREE from "three";

export function setupBoneMaterial(){
    //setup bone
    const material = new THREE.MeshPhongMaterial({
        color: 0xE3DAC9,
        flatShading: false,
        side: THREE.DoubleSide,
        opacity: 0.8,
        transparent: true
    })
    return material
}

