import React, {useRef, useEffect, useState} from "react";
import {useSize} from 'react-use';
import { connect } from "react-redux";
import video from './video.js';
import * as _ from "lodash";
import {BASE_URL} from "../../../util/urls";
import {FILE_TYPE_AVI, MP4_FILE_ENDING} from "../../../util/constants";
import ErrorBoundary from "../../../error/error-boundary";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
}));

const getWindowDimensions = (ref) => {
    const { clientWidth: width, clientHeight: height } = ref.current;
    return {
        width,
        height
    };
};

function VideoComponent(props){

    const classes = useStyles();
    const [parentHeight, setParentHeight] = useState('20vh')
    const [parentWidth, setParentWidth] = useState('100%')
    const [currentVideoFilePath, setCurrentVideoFilePath] = useState('')
    const [previousVideoFilePath, setPreviousVideoFilePath] = useState('')


    function filterMp4Path(){
        if(belastungsfall
            && belastungsfall[belastungsfallId]
            && belastungsfall[belastungsfallId].fileData
            && belastungsfall[belastungsfallId].fileData.fileId) {

            const fileId = belastungsfall[belastungsfallId].fileData.fileId

            if (fileData && !_.isEmpty(fileData)
                && !_.isEmpty(fileData[fileId].data)){

                const aviData = fileData[fileId].data.find(set => set.type === FILE_TYPE_AVI);
                const pathVariables = aviData.path.split('/');

                return BASE_URL+ '/' + pathVariables.slice(1, pathVariables.length - 1).join('/') + '/' + fileId + MP4_FILE_ENDING;
            } else {
                return '';
            }
        }else{
            return '';
        }
    }

    const updateVideoFilePath = () =>{
        setPreviousVideoFilePath(currentVideoFilePath)
        setCurrentVideoFilePath(filterMp4Path())
    }

    const { belastungsfallId, belastungsfall, fileData} = props;
    const videoContainerRef = useRef(null);
    const videoRef = useRef();

    useEffect ( () => {
        if(videoContainerRef.current) {
            setParentHeight(videoContainerRef.current.height);
            setParentWidth(videoContainerRef.current.width);
        }
    }, [parentHeight, setParentHeight]);

    useEffect(()=>{
        updateVideoFilePath()
        if(currentVideoFilePath !== '' && currentVideoFilePath !== previousVideoFilePath) {
            videoRef.current.setAttribute('src', currentVideoFilePath);
            if(videoRef.current) {
                videoRef.current.style.width = "100%";
            }
        }
    },[belastungsfall,belastungsfallId, fileData, videoContainerRef])

    return(
        <ErrorBoundary>
            <div ref={videoContainerRef}>
                <video width={parentWidth} height={parentHeight} ref={videoRef} id={`video${belastungsfallId}`}/>
            </div>
        </ErrorBoundary>
    )
}

const mapStateToProps= (state)=>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        fileData: state.fileDatas.fileData,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VideoComponent);
