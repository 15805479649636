import axios from 'axios';
import {
    FETCH_FILE_DATA_FAILURE,
    FETCH_FILE_DATA_SUCCESS,
    FETCH_FILE_DATA_REQUEST,
    FETCH_DATA_PATH_DATA_REQUEST,
    FETCH_DATA_PATH_DATA_SUCCESS,
    FETCH_DATA_PATH_DATA_FAILURE,
    SAVE_DATA_PATH_URL,
} from "./FileData.type";
import {BASE_URL, DATA} from "../../util/urls";
import {AVI_FILE_ENDING, MP4_FILE_ENDING} from "../../util/constants";
import {config} from "../../config/axiosConfig";

const requestURL = (implantId,
                    activityId,
                    activityIndentationLevel,
                    parameterId,
                    parameterIndentationLevel,
                    patientId,
                    fileId) => {
    return BASE_URL+DATA+`implantId=${implantId}&activityId=${activityId}&activityIndentationLevel=${activityIndentationLevel}&parameterId=${parameterId}&parameterIndentationLevel=${parameterIndentationLevel}&patientId=${patientId}&fileId=${fileId}&fileType=t`;
}
const fileRequestURL = (filepath) =>{
    if(filepath.endsWith(AVI_FILE_ENDING)){
        const filepathSplit = filepath.split('.');
        return BASE_URL+filepathSplit[0]+MP4_FILE_ENDING;
    }
    return BASE_URL+filepath
}

export const fetchFileDatas = (implantId,
                               activityId,
                               activityIndentationLevel,
                               parameterId,
                               parameterIndentationLevel,
                               patientId,
                               fileId) => {
    // wenn bealstungfall schon im store, dann nicht noch einen Api call
    return (dispatch) => {
        // set loading state
        dispatch(fetchFileDataRequest())
        axios.get(requestURL(   implantId,
                                activityId,
                                activityIndentationLevel,
                                parameterId,
                                parameterIndentationLevel,
                                patientId,
                                fileId), config)
            .then(response => {
                const data = response.data
                dispatch(fetchFileDataSuccess(data, fileId))
            })
            .catch(error => {
                dispatch(fetchFileDataFailure(error.message))
            })
    };
}

export const fetchFileDataRequest = () => {
    return{
        type: FETCH_FILE_DATA_REQUEST
    }
}
export const fetchFileDataFailure = (error) => {
    return {
        type: FETCH_FILE_DATA_FAILURE,
        payload: error
    }
}
export const fetchFileDataSuccess = (data, fileId) => {
    return{
        type: FETCH_FILE_DATA_SUCCESS,
        payload: {data, fileId}
    }
}

export const fetchDataPathData = (filepath, filePathId, fileId) =>{
    return (dispatch) => {
        dispatch(fetchDataPathDataRequest(filePathId, fileId))
        axios.get(fileRequestURL(filepath), config)
            .then(response => {
                const data = response.data
                dispatch(fetchDataPathDataSuccess(data, filePathId, fileId))

            })
            .catch(error => {
                dispatch(fetchDataPathDataFailure(error.message, filePathId, fileId))
            })
    };
}


export const fetchDataPathDataRequest = (filePathId, fileId) => {
    return{
        type: FETCH_DATA_PATH_DATA_REQUEST,
        payload: {filePathId, fileId}
    }
}
export const fetchDataPathDataSuccess = (data, filePathId, fileId) => {
    return {
        type: FETCH_DATA_PATH_DATA_SUCCESS,
        payload: {data, filePathId, fileId}
    }
}
export const fetchDataPathDataFailure = (error, filePathId, fileId) => {
    return{
        type: FETCH_DATA_PATH_DATA_FAILURE,
        payload: {error, filePathId, fileId}
    }
}

export const fetchDataPathURL = (filepath, filePathId, fileId) => {
    return (dispatch) => dispatch({
        type: SAVE_DATA_PATH_URL,
        payload: {filepathURL: fileRequestURL(filepath), filePathId, fileId}
    })
}
