import {combineReducers} from 'redux'
import belastungsfallReducer from "./belastungsfall/Belastungsfall.reducer";
import activityParameterReducer from "./activityParameter/ActivityParameter.reducer";
import parameterPatientReducer from "./parameterPatient/ParameterPatient.reducer";
import fileDataReducer from "./fileData/FileData.reducer";
import patientFileReducer from "./patientFile/PatientFile.reducer";
import fullTextSearchReducer from "./fullTextSearch/FullTextSearch.reducer";
import fileSearchReducer from "./fileSearch/FileSearch.reducer";
import implantActivityReducer from "./implantActivity/ImplantActivity.reducer";
import measurementsReducer from "./measurements/Measurements.reducer";
import vectorSceneReducer from "./vectors/Vector.reducer";
import belastungsfallMetaDataReducer from "./belastungsfallMetaData/BelastungsfallMetaData.reducer";

const rootReducer = combineReducers({
    belastungsfaelle: belastungsfallReducer,
    activityParameters: activityParameterReducer,
    parameterPatients: parameterPatientReducer,
    fileDatas: fileDataReducer,
    patientFiles: patientFileReducer,
    fullTextSearch: fullTextSearchReducer,
    fileSearch: fileSearchReducer,
    implantActivities: implantActivityReducer,
    measurements: measurementsReducer,
    vectorScenes: vectorSceneReducer,
    belastungsfallMetaData: belastungsfallMetaDataReducer,
})

export default rootReducer;
