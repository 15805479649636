import React, {useEffect, useState} from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    AKF_FILE_ENDING,
    COF_FILE_ENDING,
    EOF_FILE_ENDING,
    IOF_FILE_ENDING,
    DATA_INCLUDED_IN,
} from "../../../util/constants";
import {connect} from "react-redux";
import {
    isChartDataReady,
    getFileEnding
} from "../../../util/helpingFunctions";
import ErrorBoundary from "../../../error/error-boundary";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Colors} from "../../../util/colors";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {Dimensions} from "../../../util/dimensions";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: Colors.primaryV2,
        color: theme.palette.common.white,
        fontSize: 12,
    },
    body: {
        padding: theme.spacing(1),
        fontSize: 12,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(theme => ({
    root:{

    },
    table: {
        width: '100%',
        boxShadow: '1px 2px 10px 5px rgba(255,255,255,0.5)',
        backgroundColor: Colors.background,
    },

    container: {
        width: '100%',
        boxShadow: '0px 1px 3px rgba(0,0,0,0.5)',
        borderRadius: Dimensions.borderRadius,
    },
}));

function MeasureingInformation(props){

    const { belastungsfallId,
            belastungsfall,
            dataPathData,
            fileData} = props;

    const [rows, setRows] = useState()
    const classes = useStyles();

    useEffect(()=>{
        if(dataPathData && isChartDataReady({belastungsfallId, belastungsfall, dataPathData})){
            const fileId = belastungsfall[belastungsfallId].fileData.fileId
            const fileDatasOfFileId = dataPathData[fileId];
            const fileEnding = getFileEnding(fileDatasOfFileId);
            const lines = dataPathData[fileId][`${fileId}${fileEnding}`].filePathData.split('\n')
            const dataFileComments = []
                fileData[fileId].data
                .forEach(fileInfo => {
                    if(fileInfo.comment !== ""
                    && (fileInfo.path.endsWith(AKF_FILE_ENDING)
                        || fileInfo.path.endsWith(COF_FILE_ENDING)
                        || fileInfo.path.endsWith(IOF_FILE_ENDING)
                        || fileInfo.path.endsWith(EOF_FILE_ENDING))){

                        dataFileComments.push(fileInfo)
                    }
                })

            if(dataFileComments.length > 1){
                throw Error('There should only be one Comment')
            }
            let firstLineComment = ""
            if(dataFileComments.length !== 0){
                firstLineComment = dataFileComments[0].comment.split(':').slice(1)
            }
            setRows([
                createData(DATA_INCLUDED_IN, firstLineComment),
                createData(lines[4].slice(0, 16),lines[4].slice(16) ),
                createData(lines[5].slice(0, 16),lines[5].slice(16) ),
                createData(lines[7].slice(0, 10),lines[7].slice(10) ),
                createData(lines[8].slice(0, 10),lines[8].slice(10) ),
                createData(lines[10].split(':')[0],lines[10].split(':') ),
                createData(lines[12].split(':')[0],lines[12].split(':') ),
                createData(lines[14].split(':')[0],lines[14].split(':') ),
            ])
        }

    },[belastungsfall])

    function createData(name, value) {
        return { name, value};
    }

    return rows ? (
        <ErrorBoundary>
            <div className={classes.root}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Measurement Information</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer className={classes.container}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableBody>
                                    {rows.map((row) => (
                                        <StyledTableRow key={row.name}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{row.value}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </div>
        </ErrorBoundary>
    ):(
        null
    )
}

const mapStateToProps = (state) =>{
    return {
        belastungsfall: state.belastungsfaelle.belastungsfall,
        dataPathData: state.fileDatas.dataPathData,
        fileData: state.fileDatas.fileData,
    }
}
export default connect(
    mapStateToProps
)(MeasureingInformation);
