import {
    SET_BELASTUNGSFALL_JOINT_NAME,
    DELETE_BELASTUNGSFALL_META_DATA,
    SET_BELASTUNGSFALL_ACTIVITY_NAME,
    SET_BELASTUNGSFALL_PARAMETER_NAME,
    SET_BELASTUNGSFALL_FILE_NAME,
} from "./BelastungsfallMetaData.type.js";

const initialState = {
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BELASTUNGSFALL_JOINT_NAME:
                return{
                    ...state,
                    [action.payload.newBelastungsfallId]: {
                        ...state[action.payload.newBelastungsfallId],
                        name: action.payload.jointName,
                        number: action.payload.newBelastungsfallId,
                    }
                }
        case SET_BELASTUNGSFALL_ACTIVITY_NAME:
                return{
                    ...state,
                    [action.payload.newBelastungsfallId]: {
                        ...state[action.payload.newBelastungsfallId],
                        activityName: action.payload.activityName,
                        number: action.payload.newBelastungsfallId,
                    }
                }
        case SET_BELASTUNGSFALL_PARAMETER_NAME:
                return{
                    ...state,
                    [action.payload.newBelastungsfallId]: {
                        ...state[action.payload.newBelastungsfallId],
                        parameterName: action.payload.parameterName,
                        number: action.payload.newBelastungsfallId,
                    }
                }
        case SET_BELASTUNGSFALL_FILE_NAME:
                return{
                    ...state,
                    [action.payload.newBelastungsfallId]: {
                        ...state[action.payload.newBelastungsfallId],
                        fileName: action.payload.fileName,
                        number: action.payload.newBelastungsfallId,
                    }
                }
        case DELETE_BELASTUNGSFALL_META_DATA:
            var stateCopy = {...state};
            delete stateCopy[action.payload.belastungsfallId]
            return {
                ...stateCopy
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
