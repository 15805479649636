import * as d3 from "d3";


export const Dimensions = {
    borderRadius: 0,
}

export const ChartBaseDimensions = {
    chart: {
        componentHeight: 400,
        componentWidth: 1000,
        margin: {
            top: 30,
            right: 80,
            bottom: 80,
            left: 80
        },
        labelXHeight: 25,
        leftYLabelWidth: -65,
    },
    brushChart:{
        componentHeight: 500,
        margin: {
            top: 370,
            right: 80,
            bottom: 40,
            left: 80
        },
    },
    ortholoadLogo: {
        height: 60,
        padding: 10,
    }

}

export const ChartDimensions = {
    chartWidth: ChartBaseDimensions.chart.componentWidth - ChartBaseDimensions.chart.margin.left
        - ChartBaseDimensions.chart.margin.right,
    chartHeight: ChartBaseDimensions.chart.componentHeight - ChartBaseDimensions.chart.margin.top
        - ChartBaseDimensions.chart.margin.bottom,
    chartLeftYLabelHeight: -(ChartBaseDimensions.chart.componentHeight - ChartBaseDimensions.chart.margin.top
        - ChartBaseDimensions.chart.margin.bottom)/2,
    brushChartHeight: ChartBaseDimensions.brushChart.componentHeight - ChartBaseDimensions.brushChart.margin.top
        - ChartBaseDimensions.brushChart.margin.bottom,
    leftYLabelHeigth: 0,
    rightYLabelWidth: 50,
    rightYLabelHeigth: 0,
    ortholoadLogo: {
        xPosition: ChartBaseDimensions.ortholoadLogo.padding,
        yPosition: ChartBaseDimensions.chart.componentHeight - ChartBaseDimensions.chart.margin.top
            - ChartBaseDimensions.chart.margin.bottom - ChartBaseDimensions.ortholoadLogo.height
            - ChartBaseDimensions.ortholoadLogo.padding,
    }
}

export const DynamicChartBaseDimensions = {
    xScale : d3.scaleLinear().range([0, ChartDimensions.chartWidth]),
    leftYAxisScale : d3.scaleLinear().range([ChartDimensions.chartHeight, 0]),
    rightYAxisScale : d3.scaleLinear().range([ChartDimensions.chartHeight, 0]),
    yScalebr : d3.scaleLinear().range([ChartDimensions.brushChartHeight, 0]),
    brushChartXScale :d3.scaleLinear().range([0, ChartDimensions.chartWidth]),
    brushChartYScale : d3.scaleLinear().range([ChartDimensions.brushChartHeight, 0]),
}

export const DynamicChartDimensions = {
    xAxis : d3.axisBottom().scale(DynamicChartBaseDimensions.xScale),
    yAxis : d3.axisLeft().scale(DynamicChartBaseDimensions.leftYAxisScale),
    nAxis : d3.axisRight().scale(DynamicChartBaseDimensions.rightYAxisScale),
    xAxisbr : d3.axisBottom().scale(DynamicChartBaseDimensions.brushChartXScale),
}
