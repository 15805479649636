import {
    ChartBaseDimensions,
    ChartDimensions,
    DynamicChartBaseDimensions,
    DynamicChartDimensions
} from "../../../util/dimensions";
import * as d3 from "d3";
import {
    NEWTON_1ST_CHART_LABEL,
    BW_1ST_CHART_LABEL,
    NEWTON_2ND_CHART_LABEL,
    BW_2ND_CHART_LABEL
} from "../../../util/constants";
import video from "../video/video";
import {vect_update} from "../vectors/VectorUtil";
import {d3ChartColor} from "../../../util/colors";

export function updateBrushSVG(brushSVG,chartDataStore,lineBrush){
    //brush graph update section
    let updatedBrushSVG = brushSVG.selectAll('.brushPath').data(chartDataStore.state.activeSlices);
    //delete old paths
    updatedBrushSVG.exit().remove();
    //append new paths
    let enteruContext = updatedBrushSVG.enter()
        .append('g')
        .append('path')
        .attr('class', 'brushPath')
        .style('stroke-dasharray', function (d) {
            if (d.id === 'Mx' || d.id === 'My' || d.id === 'Mz' || d.id === 'M') {
                return 4;
            }
        });
    updatedBrushSVG = enteruContext.merge(updatedBrushSVG);
    updatedBrushSVG
        .transition().duration(500)
        .attr('d', function (d) {
            return lineBrush(d.values);
        })
        .attr('fill', 'none')
        .attr('stroke', function (d) {
            return d3ChartColor(d.id);
        })
        .attr('stroke-width', 2);
}

export function updateTopGraphChartObject(chartContainerObject,chartDataStore,lineF,lineM){
    //TopChartObject graph update section
    let updatedTopChartObject = chartContainerObject.selectAll('.graphPath').data(chartDataStore.state.activeSlices);
    //delete old paths
    updatedTopChartObject.exit().remove();
    //append new paths
    let enterUFocus = updatedTopChartObject.enter()
        .append('g')
        .append('path')
        .attr('class', 'graphPath')
        .style('stroke-dasharray', function (d) {
            if (d.id === 'Mx' || d.id === 'My' || d.id === 'Mz' || d.id === 'M') {
                return 4;
            }
        });
    updatedTopChartObject = enterUFocus.merge(updatedTopChartObject);
    updatedTopChartObject
        .transition().duration(500)
        .attr('d', function (d) {
            if (d.id === 'Fx' || d.id === 'Fy' || d.id === 'Fz' || d.id === 'F') {
                return lineF(d.values);
            } else {
                return lineM(d.values);
            }
        })
        .attr('fill', 'none')
        .attr('stroke', function (d) {
            return d3ChartColor(d.id);
        })
        .attr('stroke-width', 2)
        .attr('clip-path', 'url(#clip)'); //clip lines outside of the axis;

}

export function updateGirdVisibilityControl(dataGraph,chartDataStore,measurements, belastungsfallId){
    let gridlines = d3.axisLeft()
        .tickFormat('')
        .tickSize(-ChartDimensions.chartWidth)
        .scale(DynamicChartBaseDimensions.leftYAxisScale);

    let update_grid = dataGraph.selectAll('.grid')
        .data([chartDataStore.state.activeSlices], function (d) {
            return d;
        })


    // Gridline update
    update_grid
        .enter()
        .append('g')
        .attr('class', 'grid')
        .merge(update_grid)
        .call(gridlines);

    if(measurements && measurements[belastungsfallId]) {
        toggleGridVisibility(measurements[belastungsfallId].tGrid)
    }

}

export function makeGridVisible(){
    d3.selectAll(".grid")
        .transition()
        .duration(500)
        .style("opacity", 0.3)
}

export function makeGridInvisible(){
    d3.selectAll(".grid")
        .transition()
        .duration(500)
        .style("opacity", 0)
}

export function updateLabelUnitControl(){

}

export function toggleCursorVisibilitiesOf(measure){
    Object.keys(measure).forEach(measureKey => {
        if (measure[measureKey] === true && !(measureKey === 'tGrid' || measureKey === 'tUnit')) {
            d3.select(`#${measureKey} circle`).style('opacity', '1');
            d3.select(`#${measureKey} text`).style('opacity', '1');
        }
    })
}

export function constructActiveSlices(measurementSwitches, chartDataStore) {

    if (measurementSwitches.tUnit) {
        Object.assign(chartDataStore.state.slicesUsr, chartDataStore.state.slices);
        d3.select('.y.axis').select('text')
            .attr('y',  ChartBaseDimensions.chart.leftYLabelWidth)
            .attr('x', ChartDimensions.chartLeftYLabelHeight)
            .text('Newton');
        d3.select('.n.axis').select('text')
            .text('Nm (dashed line)');
    } else {
        Object.assign(chartDataStore.state.slicesUsr, chartDataStore.state.slicesBW);
        d3.select('.y.axis').select('text')
            .attr('y',  ChartBaseDimensions.chart.leftYLabelWidth)
            .attr('x', ChartDimensions.chartLeftYLabelHeight)
            .text(BW_1ST_CHART_LABEL);
        d3.select('.n.axis').select('text')
            .text(BW_2ND_CHART_LABEL);
    }
    chartDataStore.state.activeSlices[0] = measurementSwitches.Fx ? chartDataStore.state.slicesUsr.slice(0, 1)[0] :
        {id: 'Fx', values: {time: 0, measurement: 0}}
    chartDataStore.state.activeSlices[1] = measurementSwitches.Fy ? chartDataStore.state.slicesUsr.slice(1, 2)[0] :
        {id: 'Fy', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[2] = measurementSwitches.Fz ? chartDataStore.state.slicesUsr.slice(2, 3)[0] :
        {id: 'Fz', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[3] = measurementSwitches.F ? chartDataStore.state.slicesUsr.slice(3, 4)[0] :
        {id: 'F', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[4] = measurementSwitches.Mx ? chartDataStore.state.slicesUsr.slice(4, 5)[0] :
        {id: 'Mx', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[5] = measurementSwitches.My ? chartDataStore.state.slicesUsr.slice(5, 6)[0] :
        {id: 'My', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[6] = measurementSwitches.Mz ? chartDataStore.state.slicesUsr.slice(6, 7)[0] :
        {id: 'Mz', values: {time: 0, measurement: 0}};
    chartDataStore.state.activeSlices[7] = measurementSwitches.M ? chartDataStore.state.slicesUsr.slice(7, 8)[0] :
        {id: 'M', values: {time: 0, measurement: 0}};

    if (measurementSwitches.Mx || measurementSwitches.My ||
        measurementSwitches.Mz || measurementSwitches.M) {
        d3.select('.n.axis')
            .style('opacity', 1);
    } else {
        d3.select('.n.axis')
            .style('opacity', 0);
    }

    if (measurementSwitches.Fx || measurementSwitches.Fy ||
        measurementSwitches.Fz || measurementSwitches.F) {
        //change 1st Y axis label
        d3.select('.y.axis').select('text')
            .attr('y',  ChartBaseDimensions.chart.leftYLabelWidth)
            .attr('x', ChartDimensions.chartLeftYLabelHeight)
            .text(function () {
                if (measurementSwitches.tUnit) {
                    return 'Newton*m';
                } else {
                    return BW_1ST_CHART_LABEL;
                }
            })
            .style('opacity', 1);

    } else {
        d3.select('.y.axis')
            .style('opacity', 0);
    }
}

export function addMouseOverChartListener(mouse, belastungsfallId, vectorScenes, chartDataStore){
    var lines = document.getElementsByClassName('graphPath');

    // mouse moving over canvas
    mouse.on('mousemove', function () {

        let mouse = d3.mouse(this);
        d3.selectAll('.circle-cursors')
            .attr('time_pos', DynamicChartBaseDimensions.xScale.invert(mouse[0])) //create attr for PatVideo to get mouse pos at time x
            .attr('transform', function (d, i) {
                if (lines[i].getTotalLength() !== 0) {
                    //var xTime = xScale.invert(mouse[0]), //get mouse coordinates with mouse[0] and mouse[1]
                    let beginning = 0,
                        end = lines[i].getTotalLength(),
                        target = null;
                    while (true) {
                        target = Math.floor((beginning + end) / 2);
                        var pos = lines[i].getPointAtLength(target);
                        if ((target === end || target === beginning) && pos.x !== mouse[0]) {
                            break;
                        }
                        if (pos.x > mouse[0]) end = target;
                        else if (pos.x < mouse[0]) beginning = target;
                        else break; //position found
                    }
                    var a = this.id;
                    if (a === 'Fx' || a === 'Fy' || a === 'Fz' || a === 'F') {
                        d3.select(this).select('text')
                            .text(DynamicChartBaseDimensions.leftYAxisScale.invert(pos.y).toFixed(2)); //+'% BW');
                        d3.select(this).select('.circle-cursors circle')
                            .style('opacity', '1');
                        d3.select(this).select('.circle-cursors text')
                            .style('opacity', '1');
                    } else {
                        d3.select(this).select('text')
                            .text(DynamicChartBaseDimensions.rightYAxisScale.invert(pos.y).toFixed(2)); //+'% BW');
                        d3.select(this).select('.circle-cursors circle')
                            .style('opacity', '1');
                        d3.select(this).select('.circle-cursors text')
                            .style('opacity', '1');
                    }

                    return 'translate(' + mouse[0] + ',' + pos.y + ')';
                }
            });
        video.updateFrame(belastungsfallId)
        vect_update(vectorScenes, belastungsfallId, chartDataStore)
    });

}
export function updateBushDomain(chartDataStore,brushSVG){
    if (!chartDataStore.state.translation.k === undefined || !chartDataStore.state.translation.k === true) {
        DynamicChartBaseDimensions.brushChartXScale.domain(DynamicChartBaseDimensions.xScale.domain());
    }
    DynamicChartBaseDimensions.brushChartYScale.domain(DynamicChartBaseDimensions.leftYAxisScale.domain());

    brushSVG.select('.x.axisbr')
        .style('font-size', '16px')
        .call(DynamicChartDimensions.xAxisbr);
    /*** all axis done ***/
    /*** tooltip and mouse movements ***/
}

export function updateTopChartContainer(chartDataStore,chartContainerObject, measurements, belastungsfallId){
    let yF_total_min_value = d3.min(chartDataStore.state.activeSlices, function (c) {
        return d3.min(c.values, function (d) {
            return d.measurement;
        });
    });
    let yM_total_min_value = d3.min(chartDataStore.state.activeSlices.slice(4, 8), function (c) {
        return d3.min(c.values, function (d) {
            return d.measurement;
        });
    });
    let yF_total_max_value = d3.max(chartDataStore.state.activeSlices, function (c) {
        return d3.max(c.values, function (d) {
            return d.measurement;
        });
    }) * (1.02); //factor to create margin top
    let yM_total_max_value = d3.max(chartDataStore.state.activeSlices.slice(4, 8), function (c) {
        return d3.max(c.values, function (d) {
            return d.measurement;
        });
    }) * (1.02);

    fillChartContainer(chartContainerObject, measurements, belastungsfallId)

    // Create the X axis:
    if (!chartDataStore.state.translation.k === undefined || !chartDataStore.state.translation.k === true) {
        DynamicChartBaseDimensions.xScale.domain([0, d3.max(chartDataStore.state.activeSlices, function (c) {
            return d3.max(c.values, function (d) {
                return d.time;
            });
        })]);
        chartContainerObject.select('.x.axis')
            .transition()
            .style('font-size', '16px')
            .duration(500)
            .call(DynamicChartDimensions.xAxis);
    }

    // create the Y axis
    DynamicChartBaseDimensions.leftYAxisScale.domain([yF_total_min_value, yF_total_max_value]);
    chartContainerObject.select('.y.axis')
        .style('font-size', '16px')
        .call(DynamicChartDimensions.yAxis);

    // create the 2nd Y axis
    DynamicChartBaseDimensions.rightYAxisScale.domain([yM_total_min_value, yM_total_max_value]);
    chartContainerObject.select('.n.axis')
        .style('font-size', '16px')
        .call(DynamicChartDimensions.nAxis);
}

export function toggleGridVisibility(tGridToggeld){
    tGridToggeld ? makeGridVisible() : makeGridInvisible()
}

export function toggle1STChartLabel(tUnitToggled){
    if(tUnitToggled){
        return NEWTON_1ST_CHART_LABEL;
    }else{
        return BW_1ST_CHART_LABEL;
    }
}


export function toggle2NDChartLabel(tUnitToggled){
    if(tUnitToggled){
        return NEWTON_2ND_CHART_LABEL;
    }else{
        return BW_2ND_CHART_LABEL;
    }
}

export function initializeChartContainer(chartRef, measurements, belastungsfallId){
    let chartContainerObject = d3.select(chartRef.current).select('svg')
        .append('g')
        .attr('class', `focus${belastungsfallId}`)
        .attr('transform', 'translate(' + ChartBaseDimensions.chart.margin.left + ',' + ChartBaseDimensions.chart.margin.top + ')');

    fillChartContainer(chartContainerObject, measurements, belastungsfallId)

    return chartContainerObject
}

function fillChartContainer(chartContainerObject, measurements, belastungsfallId){
    let tUnitBoolean
    if (measurements && measurements[belastungsfallId]){
        tUnitBoolean = measurements[belastungsfallId].tUnit;
    }else{
        tUnitBoolean = false
    }

    // Creating X axis
    chartContainerObject.append('g')
        .attr('transform', 'translate(0,' + ChartDimensions.chartHeight + ')')
        .attr('class', 'x axis')
        // X axis annotations
        .append('text')
        .attr('y',  ChartBaseDimensions.chart.labelXHeight)
        .attr('x', ChartDimensions.chartWidth / 2)
        .attr('dy', '.75em')
        .style('text-anchor', 'middle')
        .text('time (s)')
        .style('font-size', '16px')
        .style('opacity', 1);

    // Creating Y axis
    chartContainerObject.append('g')
        .attr('class', 'y axis')
        // Y axis annotations
        .append('text')
        .attr('y',  ChartBaseDimensions.chart.leftYLabelWidth)
        .attr('x', ChartDimensions.chartLeftYLabelHeight)
        .attr('dy', '.75em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(toggle1STChartLabel(tUnitBoolean))
        .style('font-size', '16px')
        .style('opacity', 0)
        .style('opacity', 1);

    // Create the moments Y axis
    chartContainerObject.append('g')
        .attr('transform', 'translate(' + ChartDimensions.chartWidth + ',0)')
        .attr('class', 'n axis')
        .style('opacity', 1)
        //N axis annotations
        .append('text')
        .attr('y', ChartDimensions.rightYLabelWidth)
        .attr('x', ChartDimensions.chartLeftYLabelHeight)
        .attr('dy', '.75em')
        .attr('transform', 'rotate(-90)')
        .style('text-anchor', 'middle')
        .text(toggle2NDChartLabel(tUnitBoolean))
        .style('font-size', '16px');
}

export function check_usr_input(measure){
    toggleCursorVisibilitiesOf(measure)
}

//if condition escapes error on initialization
function ids(id, chartDataStore) {
    if (id < 8) {
        return chartDataStore.state.slices[id++].id;
    }
}
