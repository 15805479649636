import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import PropTypes from "prop-types";
import {Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import {Colors} from "../../../util/colors";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

OrtholoadAccordion.propTypes = {
    name: PropTypes.string,
    dataTestID: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
        color: Colors.primary,
        fontWeight: 100
    },
    content: {
        width: '100%',
        flexDirection: 'column',
        margin: theme.spacing(0),
    },
    expand:{
        backgroundColor: Colors.primaryV2,
        borderRadius: '50%',
        color: Colors.background,
        boxShadow: '0px 1px 3px rgba(0,0,0,0.5)',
    }
}));

const Accordion = withStyles({
    root: {
        width: '100%',
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
    root: {
        backgroundColor:Colors.secondary,
        margin: theme.spacing(0),
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: theme.spacing(1),
        '&$expanded': {
            minHeight: theme.spacing(1),
            margin: theme.spacing(0),
        },
    },
    content: {
        '&$expanded': {
            minHeight: theme.spacing(2),
            margin: '5px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        width:'100%',
        padding: theme.spacing(0.1),
    },
}))(MuiAccordionDetails);


export default function OrtholoadAccordion({children, name, dataTestID}){
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return(
        <Accordion expanded={expanded === 'panel2'}
                   onChange={handleChange('panel2')}
                   data-testid={dataTestID}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expand}/>}>
                <Typography className={classes.heading}>{name}</Typography>
            </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {children}
                    </div>
                </AccordionDetails>
        </Accordion>
    )
}
