import Box from '@mui/material/Box';
import styled from '@emotion/styled'
import Button from '@mui/material/Button';
import {
    INTRODUCTION,
    ADD_CASE_STUDY,
    OPEN_CASE_STUDY_ACCORDION,
    SELECT_CASE_STUDY_FROM_LIST,
    OPEN_ACTIVITY_ACCORDION,
    SELECT_THE_FIRST_BOX_WITHOUT_TREE_EXPANDED,
    OPEN_PARAMTER_ACCORDION,
    SELECT_ANY_PARAMETER,
    OPEN_PATIENT_ACCORDION,
    SELECT_ANY_PATIENT,
    OPEN_FILE_ACCORDION,
    SELECT_ANY_FILE,
    OPEN_DATA_ACCORDION,
    SELECT_ANY_DATA,
    CONTINUE_TO_SITE,
} from "./tutorialStage";
import { ModalUnstyled } from '@mui/base';
import {useEffect, useState} from "react";
import ErrorBoundary from "../../../error/error-boundary";
import {screen} from '@testing-library/dom'
import userEvent from "@testing-library/user-event";
import {drawerWidth, INITIAL_GELENK_NAME, ORTHOLOAD_LOGO_URL} from "../../../util/constants";
import {theme} from "../../../styles/App.styles";


const StyledModal =  styled(ModalUnstyled)`
        position: fixed;
        z-index: 1300;
        right: 0;
        bottom: 0;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

const ortholoadImgStyle = {
    width: theme.spacing(15)
};
const Backdrop1 = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: ${drawerWidth};
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
`;

export function IntroductionModal() {
    const [firstModalOpen, setFirstModalOpen] = useState(true);
    const [currentTitle, setCurrentTitle] = useState("Welcome to the workHEALTH - Data Dashboard")
    const [currentText, setCurrentText] = useState("")
    const [currentBackdrop, setCurrentBackdrop] = useState(Backdrop1)
    const [currentPrevtageKey] = useState(CONTINUE_TO_SITE)
    const [currentNextStageKey, setCurrentNextStageKey] = useState(ADD_CASE_STUDY)
    const [currentIsInitialModal, setCurrentIsInitialModal] = useState(true)


    const nextStage = async (stage) => {
        switch (stage){
            case INTRODUCTION:
                break;
            case ADD_CASE_STUDY:
                userEvent.click(screen.getByTestId('add-case-study'))
                setCurrentTitle("Add a case Study")
                setCurrentText("To Add a case study, push on the big plus button")
                setCurrentBackdrop(Backdrop1)
                setCurrentNextStageKey(OPEN_CASE_STUDY_ACCORDION)
                setCurrentIsInitialModal(false)
                break;
            case OPEN_CASE_STUDY_ACCORDION:
                userEvent.click(screen.getAllByText(INITIAL_GELENK_NAME)[0])
                setCurrentTitle("Configure the case study")
                setCurrentText("Open the case study configuration.")
                setCurrentNextStageKey(SELECT_CASE_STUDY_FROM_LIST)
                break;
            case SELECT_CASE_STUDY_FROM_LIST:
                userEvent.click(screen.getAllByText(INITIAL_GELENK_NAME)[1])
                setCurrentTitle("Select an implant")
                setCurrentText("each implant has a specific name.")
                setCurrentNextStageKey(OPEN_ACTIVITY_ACCORDION)
                break;
            case OPEN_ACTIVITY_ACCORDION:
                userEvent.click(screen.getByText('Hip Joint III'))
                setCurrentTitle("Configure the Activity")
                setCurrentText("Each implant was exhibited through performing and measuring an activity. Choose the activity here.")
                setCurrentNextStageKey(SELECT_THE_FIRST_BOX_WITHOUT_TREE_EXPANDED)
                break;
            case SELECT_THE_FIRST_BOX_WITHOUT_TREE_EXPANDED:
                userEvent.click(screen.getByText('Activity'))
                setCurrentTitle("Configure the Activity")
                setCurrentText("Select an activity form the tree.")
                setCurrentNextStageKey(OPEN_PARAMTER_ACCORDION)
                break;
            case OPEN_PARAMTER_ACCORDION:
                userEvent.click(screen.getByTestId('activity-checkbox-2364-1').querySelector('input[type="checkbox"]'))
                setCurrentTitle("Select any Activity")
                setCurrentText("Any of the activites can be selected. Explore the dropdowns in the selection tree.")
                setCurrentNextStageKey(SELECT_ANY_PARAMETER)
                break;
            case SELECT_ANY_PARAMETER:
                userEvent.click(screen.getByText('Activity'))
                userEvent.click(screen.getByText('Parameter'))
                setCurrentTitle("Select Parameter")
                setCurrentText("Open the parameter selection menue")
                setCurrentNextStageKey(OPEN_PATIENT_ACCORDION)
                break;
            case OPEN_PATIENT_ACCORDION:
                userEvent.click(screen.getByTestId('parameter-checkbox-1-1').querySelector('input[type="checkbox"]'))
                setCurrentTitle("Select Parameter")
                setCurrentText("Any of the parameters can be selected. Explore the dropdowns in the selection tree.")
                setCurrentNextStageKey(SELECT_ANY_PATIENT)
                break;
            case SELECT_ANY_PATIENT:
                userEvent.click(screen.getByText('Parameter'))
                userEvent.click(screen.getByText('Patient'))
                setCurrentTitle("Select Patient")
                setCurrentText("Open the patient selection menu.")
                setCurrentNextStageKey(OPEN_FILE_ACCORDION)
                break;
            case OPEN_FILE_ACCORDION:
                userEvent.click(screen.getByTestId('patient-checkbox-all-1').querySelector('input[type="checkbox"]'))
                setCurrentTitle("Select Patient ")
                setCurrentText("Make a selection of a patient or select all patients.")
                setCurrentNextStageKey(SELECT_ANY_FILE)
                break;
            case SELECT_ANY_FILE:
                userEvent.click(screen.getByText('Patient'))
                userEvent.click(screen.getByText('File'))
                setCurrentTitle("Select File")
                setCurrentText("Open the file selection menu.")
                setCurrentNextStageKey(OPEN_DATA_ACCORDION)
                break;
            case OPEN_DATA_ACCORDION:
                userEvent.click(screen.getByTestId('file-checkbox-h1l_130710_1_86-1').querySelector('input[type="checkbox"]'))
                setCurrentTitle("Select File")
                setCurrentText("Select any of the file available. A Panel with the requested Data will now load.")
                setCurrentNextStageKey(SELECT_ANY_DATA)
                break;
            case SELECT_ANY_DATA:
                userEvent.click(screen.getByText('File'))
                setCurrentTitle("Configure a second Case Study")
                setCurrentText("If you want to see comparisons of case studies, configure a second Case Study the " +
                    "same wahy as before")
                setCurrentNextStageKey(CONTINUE_TO_SITE)
                break;

            case CONTINUE_TO_SITE:
                setFirstModalOpen(false)
                break;
        }
    }


    function DescriptionModal({ open,
                                title,
                                text,
                                Backdrop,
                                prevStageKey,
                                nextStageKey,
                                isInitialModal=false}) {
        useEffect(()=>{
            console.log("Modal rerender")
        },[nextStageKey])

        return (
            <div>
                <StyledModal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    open={open}
                    BackdropComponent={Backdrop}
                >
                    <Box sx={{
                        width: 500,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        p: 2,
                        px: 4,
                        pb: 3,
                    }}>
                        {isInitialModal === true? (
                                <>
                                    <img src={ORTHOLOAD_LOGO_URL} style={ortholoadImgStyle} alt={"Logo"}/>
                                    <h2 id="modal-title">
                                        {title}
                                    </h2>
                                    <p id="modal-description">
                                        {text}
                                    </p>

                                    <Button data-testid="drawer-description-new-case-study"
                                            variant={"contained"}
                                            onClick={e => nextStage(nextStageKey)}
                                    >
                                        Take a Tour
                                    </Button>
                                    <Button data-testid="continue-to-site"
                                            variant={"outlined"}
                                            onClick={e => nextStage(prevStageKey)}
                                    >
                                        Continue to Site
                                    </Button>
                                </>
                            ):(

                                <>
                                    <img src={ORTHOLOAD_LOGO_URL} style={ortholoadImgStyle} alt={"Logo"}/>
                                    <h2 id="modal-title">
                                        {title}
                                    </h2>
                                    <p id="modal-description">
                                        {text}
                                    </p>

                                    <Button data-testid="description-modal"
                                            variant={"contained"}
                                            onClick={e => nextStage(nextStageKey)}
                                    >
                                        Continue
                                    </Button>
                                </>
                            )
                        }
                    </Box>
                </StyledModal>
            </div>
        );
    }

    return (
        <ErrorBoundary>
            <DescriptionModal
                open={firstModalOpen}
                title={currentTitle}
                text={currentText}
                Backdrop={currentBackdrop}
                prevStageKey={currentPrevtageKey}
                nextStageKey={currentNextStageKey}
                isInitialModal={currentIsInitialModal}
            />
        </ErrorBoundary>
    );
}
