import React, { useEffect, useState} from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActivityTreeSelectItem from './ActivityTreeSelectItem';
import {StyledTreeItem, useStyles} from '../common/TreeSelectionItem'
import {useIconToggleClick} from "useseparatetoggleclick";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {TwoDimHashMap} from "../../../../util/hashmap";
import {
    ACTIVITY_FILTER_LIST,
    GELENK_SELECTION_MAP, INITIAL_GELENK_NAME,
    INITIAL_JOINT_ID,
    INITIAL_ZERO
} from '../../../../util/constants'
import {
    fetchJointActivitiesOfBelastungsfall,
    fetchImplantActivitesSuccess,
    fetchImplantActivites
} from "../../../../redux";
import OrtholoadAccordion from "../../layout/OrtholoadAccordion";
import {createTreeComponentsFromObjects} from "../../../../util/helpingFunctions";

ActivityTreeSelect.propTypes = {
    customClasses: PropTypes.any,
};

function ActivityTreeSelect({   customClasses,
                                jointName,
                                belastungsfall,
                                belastungsfallId,
                                implantActivity,
                                fetchImplantActivitesSuccess,
                                fetchImplantActivites,
                                fetchJointActivitiesOfBelastungsfall,
                                loading}) {

    let classes = useStyles();
    let bfID = belastungsfall[belastungsfallId];
    const [JSXComponents, setJSXComponents] = useState({})
    const [implantId, setImplantId] = useState(INITIAL_JOINT_ID);
    const [prevJointName, setPrevJointName] = useState('')

    if(customClasses){
        classes = customClasses;
    }

    useEffect(()=>{
        if(GELENK_SELECTION_MAP[jointName]) {
            fetchActivityTreeSelectData(GELENK_SELECTION_MAP[jointName].reqId)
            setPrevJointName(jointName)
        }
    },[jointName])

    useEffect(()=>{
        if(implantActivity
            && implantActivity[implantId]
            && (
                (bfID === undefined || jointName !== prevJointName) // new selection for case study
             || (bfID !== undefined && bfID.implantActivity === undefined) // implantActivity present but not in
                                                                            // belastungsfall at belastungsfallId
            )
        ){
            fetchJointActivitiesOfBelastungsfall(belastungsfallId, implantId)
        }
    },[implantActivity,prevJointName])

    useEffect(()=>{

        if(implantActivity[implantId] !== undefined) {

            const components = {}
            components[INITIAL_ZERO] = {
                id: 0,
                name: 'root',
                indentationlevel: -10,
                parentId: 0
            }
            var hashMap2d = new TwoDimHashMap();
            Object.values(implantActivity[implantId]).flat().forEach(item => {
                hashMap2d.set(item.parentId, item.id, (
                    <StyledTreeItem
                        key={item.id}
                        nodeId={item.id}
                        indentationlevel={item.indentaionLevel}
                        label={item.label}
                    />
                ))
                // make components
                components[item.id] = {
                    id: item.id,
                    name: item.label,
                    indentationlevel: item.indentaionLevel,
                    parentId: item.parentId
                }
            });


            const jsxComponents = {}
            jsxComponents[INITIAL_ZERO] = (
                <StyledTreeItem
                    key={0}
                    nodeId={0}
                    indentationlevel={-10}
                    label={'Root'}
                />
            )

            Object.keys(hashMap2d.hashMap).reverse().forEach(parentId => {
                let children = Object.keys(hashMap2d.hashMap[parentId].hashMap).map(childId => {
                    return hashMap2d.get(parentId, childId);
                })
                jsxComponents[parentId] = (
                    <StyledTreeItem
                        key={parentId}
                        nodeId={parentId}
                        label={components[parentId].name}
                        indentationlevel={components[parentId].indentationlevel}
                        children={children}
                    />
                )
            })
            setJSXComponents(jsxComponents)
        }
    },[implantActivity,implantId])

    const fetchActivityTreeSelectData = (implantId) => {
        setImplantId(implantId)
        if(implantId !== INITIAL_JOINT_ID && loading === false
            && implantActivity[implantId] === undefined){ // implantActivities not loaded yet
            fetchImplantActivites(implantId)
        }else if(implantActivity[implantId]){ // implantActivities loaded
            fetchImplantActivitesSuccess(implantActivity[implantId], implantId)
        }
    }

    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();

    const getTreeItemsJSXComponents = (parentComponents, isGrandParent) => {

        return parentComponents.map( child => {
            let children = undefined;
            if (JSXComponents[child.key]
                && JSXComponents[child.key].props.children
                && JSXComponents[child.key].props.children.length > 0) {
                if( isGrandParent
                    && ACTIVITY_FILTER_LIST.includes(JSXComponents[child.key].props.label)) {
                    children = getTreeItemsJSXComponents(JSXComponents[child.key].props.children, false);
                    return(
                        <ActivityTreeSelectItem
                            key={child.props.nodeId}
                            nodeId={child.props.nodeId.toString()}
                            itemName={child.props.label}
                            belastungsfallId={belastungsfallId}
                            indentationlevel={child.props.indentationlevel}
                            implantId={implantId}
                            children={children}
                        />
                    )
                }
                if( !isGrandParent
                    && JSXComponents[child.key].props.indentationlevel > 0) {
                    children = getTreeItemsJSXComponents(JSXComponents[child.key].props.children, false);
                    return(
                        <ActivityTreeSelectItem
                            key={child.props.nodeId}
                            nodeId={child.props.nodeId.toString()}
                            itemName={child.props.label}
                            belastungsfallId={belastungsfallId}
                            indentationlevel={child.props.indentationlevel}
                            implantId={implantId}
                            children={children}
                        />
                    )
                }
            }
        });
    };

    return JSXComponents[0] !== undefined && jointName !== INITIAL_GELENK_NAME?
    (
        <OrtholoadAccordion id="orthoload-accordion-activity" name={'Activity'} dataTestID={`${belastungsfallId}-${jointName}-activity-accordion`}>
            <div className={classes.root}>
                <TreeView
                    defaultExpanded={['1']}
                    expanded={expanded2}
                    onNodeToggle={onIconToggle}
                    defaultCollapseIcon={
                        <IconIconWrapper>
                            <ExpandMoreIcon />
                        </IconIconWrapper>
                    }
                    defaultExpandIcon={
                        <IconIconWrapper>
                            <ChevronRightIcon />
                        </IconIconWrapper>
                    }
                    defaultEndIcon={<MoreHorizIcon />}
                >
                    {getTreeItemsJSXComponents(JSXComponents[0].props.children, true)}
                </TreeView>
            </div>
        </OrtholoadAccordion>
    ):(
        null
    );
}
const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        implantActivity: state.implantActivities.implantActivity,
        loading: state.belastungsfaelle.loading
    }
}
const mapDispatchToProps = dispatch => {
    return{
        fetchJointActivitiesOfBelastungsfall: (belastungsfallId, implantId) =>
            dispatch(fetchJointActivitiesOfBelastungsfall(belastungsfallId, implantId)),
        fetchImplantActivites: (implantId) =>
            dispatch(fetchImplantActivites(implantId)),
        fetchImplantActivitesSuccess: (data, implantId) =>
            dispatch(fetchImplantActivitesSuccess(data, implantId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityTreeSelect)
