import { createTheme, adaptV4Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {Colors} from "../util/colors";
import {drawerWidth} from "../util/constants";


export const theme = createTheme(adaptV4Theme({

}));


export const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
    },
    ortholoadFontColor:{
        padding: theme.spacing(1),
        color: Colors.primaryV2,
        fontStyle: 'italic',
        fontWeight: 'bolder',
    },
    dataFontColor:{
        padding: theme.spacing(0.2),
        color: Colors.primaryV1,
        fontStyle: 'italic',
        fontWeight: 'bolder',
    },
    dashboardFontColor:{
        padding: theme.spacing(0.2),
        color: Colors.primary,
        fontStyle: 'italic',
        fontWeight: 'bolder',
    },
    appBar: {
        backgroundColor: Colors.secondary,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        flexShrink: 0
    },
    dragger: {
        width: "5px",
        cursor: "ew-resize",
        padding: "4px 0 0",
        borderTop: "1px solid #ddd",
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        backgroundColor: "#f4f7f9"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create(
            'width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }
        ),

        overflowX: "hidden",
        width: theme.spacing(1) ,
        backgroundColor: Colors.secondary,
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    menueBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        backgroundColor: Colors.background,
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexDirection: 'row',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(4),
        height: '100%',
        width: '100%',
    },
}));
