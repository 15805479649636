import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './redux/store';
import {Provider} from 'react-redux';
import ErrorBoundary from "./error/error-boundary";
import {theme} from '../src/styles/App.styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

ReactDOM.render(
    <ErrorBoundary>
          <Provider store={store}>
              <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={theme}>
                    <App />
                  </ThemeProvider>
              </StyledEngineProvider>
          </Provider>
    </ErrorBoundary>,
    document.querySelector('#root')
);
