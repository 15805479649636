export const FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL = 'FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL';
export const FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL = 'FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL';
export const FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL = 'FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL';
export const FETCH_PATIENT_FILE_OF_BELASTUNGSFALL = 'FETCH_PATIENT_FILE_OF_BELASTUNGSFALL';
export const FETCH_FILE_DATA_OF_BELASTUNGSFALL = 'FETCH_FILE_DATA_OF_BELASTUNGSFALL';
export const SELECT_BELASTUNGSFALL_JOINT_NAME = 'SELECT_BELASTUNGSFALL_JOINT_NAME';
export const CHECK_BELASTUNGSFALL_ACTIVITY = 'CHECK_BELASTUNGSFALL_ACTIVITY';
export const CHECK_BELASTUNGSFALL_PARAMETER = 'CHECK_BELASTUNGSFALL_PARAMETER';
export const CHECK_BELASTUNGSFALL_PATIENT = 'CHECK_BELASTUNGSFALL_PATIENT';
export const CHECK_BELASTUNGSFALL_FILE = 'CHECK_BELASTUNGSFALL_FILE';
export const CHECK_BELASTUNGSFALL_DATA = 'CHECK_BELASTUNGSFALL_DATA';
export const SET_SLICES_BW = 'SET_SLICES_BW';
export const DELETE_BELASTUNGSFALL_DATA = 'DELETE_BELASTUNGSFALL_DATA';
