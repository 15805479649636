import React, {useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Belastungsfall from "../../drawer/belastungsfall/Belastungsfall";
import {connect} from "react-redux";
import {setBelastungsfallMetaDataName} from "../../../redux";
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import {Colors} from "../../../util/colors";
import {INITIAL_GELENK_NAME} from "../../../util/constants";


const useStyles = makeStyles((theme) => ({
    root: {
    },
    belastungsfallContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.background,
        padding: theme.spacing(0.1),

    },
    addBelastungsfall: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: theme.spacing(8),
        borderRadius: 0,
        width: '100%',
        backgroundColor: Colors.primary,
        paddingRight: theme.spacing(0),
    },
    text: {
        color: Colors.primaryV2,
    },
    cancel: {
        color: Colors.secondaryV1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: 1,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    add: {
        backgroundColor: Colors.secondaryV2,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1.7),
        paddingBottom: theme.spacing(1.7),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    chevronDown:{
        color: Colors.primaryV2,
    }
}));


function Belastungsfaelle(props) {


    const { belastungsfallMetaData,
            setBelastungsfallMetaDataName } = props;
    const classes = useStyles();

    const [belastungsfallCount, setBelastungsfallCount] = useState(0);

    useEffect(()=>{
    }, [belastungsfallMetaData])

    function addReduxBelastungsfall(){
        setBelastungsfallMetaDataName(belastungsfallCount + 1, INITIAL_GELENK_NAME);
        setBelastungsfallCount(belastungsfallCount + 1);
    }

    function renderBelastungsfaelle(){
        if(belastungsfallMetaData) {
            return(
                Object.keys(belastungsfallMetaData).map((key) => {
                    return (
                        <div className={classes.belastungsfallContainer} key={key}>
                            <Belastungsfall id={`belastungsfall-${key}`} belastungsfall={belastungsfallMetaData[key]} key={key}/>
                        </div>
                    )
                })
            )
        }else{
            return null;
        }
    }


    return (
        <div className={classes.root}>
            {renderBelastungsfaelle()}
            <div className={classes.addBelastungsfall} onClick={()=>addReduxBelastungsfall()} >
                <Tooltip title="Add Case Study" aria-label="add">
                    <Fab  data-testid="add-case-study" aria-label="add">
                        <AddIcon/>
                    </Fab>
                </Tooltip>
            </div>
        </div>

    );
}

const mapStateToProps = (state) =>{
    return{
        belastungsfallMetaData : state.belastungsfallMetaData,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        setBelastungsfallMetaDataName: (newBelastungsfallId, name) => dispatch(setBelastungsfallMetaDataName(newBelastungsfallId, name)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps)
(Belastungsfaelle);

