import * as d3 from "d3";

export function parseData(data, chartDataStore){
    var lines;
    //split flat file in lines
    lines = data.split('\n');
    //display fileName
    //read bodyweight (in [N])
    const bodyWeight = parseInt(lines[10].split('\t')[1].split('.')[0]);
    //cut of lines above data and empty line between Identifier and dataset
    lines = lines.slice(32, 33) + '\n' + lines.slice(35);
    //globally replace ',' with new line
    lines = lines.replace(/,/g, '\n');
    //parse as TSV
    chartDataStore.state.slices = d3.tsvParse(lines);
    //slice data for multigraph
    chartDataStore.state.slices = chartDataStore.state.slices.columns.slice(1).map(function (id) {
        let signed = id.slice(0, 1) === '-'; //returns true if id negative
        return {
            id: signed ? id.slice(1) : id, //cut of '-' if data negative
            values: chartDataStore.state.slices.map(function (d) {
                return {
                    // + converts string values into numbers
                    time: +d.Time,
                    measurement: +d[id]
                };
            })
        };
    });
    //handle file inconsistencies:
    //rename Marker to M
    if (chartDataStore.state.slices[7].id === 'Marker') {
        chartDataStore.state.slices[7].id = 'M';
    }
    //rename Fres to F
    if (chartDataStore.state.slices[3].id === 'Fres') {
        chartDataStore.state.slices[3].id = 'F';
    }
    //calculate M: ( M = Wurzel aus Summe der Potenz der moments
    chartDataStore.state.slices[7].values.forEach((value, i) => {
        value.measurement = Math.sqrt(Math.pow(chartDataStore.state.slices[4].values[i].measurement, 2) + Math.pow(chartDataStore.state.slices[5].values[i].measurement, 2) + Math.pow(chartDataStore.state.slices[6].values[i].measurement, 2));
    })
    //deepcopy slices
    chartDataStore.state.slicesBW = JSON.parse(JSON.stringify(chartDataStore.state.slices));
    //convert Newton to %BodyWeight
    chartDataStore.state.slicesBW.forEach((dataset, i) => {
        dataset.values.forEach((value, i) => {
            value.measurement = (value.measurement / bodyWeight) * 100;
        })
    });
}

