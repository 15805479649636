import React, { useEffect, useState} from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PatientTreeSelectItem from './PatientTreeSelectItem';
import OrtholoadAccordion from "../../layout/OrtholoadAccordion";
import {StyledTreeItem, useStyles} from '../common/TreeSelectionItem'
import {useIconToggleClick} from "useseparatetoggleclick";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {
    INITIAL_PARAMETER_ID,
} from '../../../../util/constants'
import {
    fetchParameterPatientsOfBelastungsfall,
    fetchParameterPatients
} from "../../../../redux";

PatientTreeSelect.propTypes = {
    customClasses: PropTypes.any,
};

function PatientTreeSelect(props) {

    const { customClasses,
            belastungsfall,
            belastungsfallId,
            belastungsfallMetaData,
            parameterPatient,
            fetchParameterPatients,
            fetchParameterPatientsOfBelastungsfall,
            loading} = props

    let classes = useStyles();
    const [JSXComponents, setJSXComponents] = useState({})
    const [parameterId, setParameterId] = useState(INITIAL_PARAMETER_ID);

    let bfID = belastungsfall[belastungsfallId];
    if(customClasses){
        classes = customClasses;
    }

    useEffect(()=>{
        if(bfID
            && bfID.checkedParameter
            && bfID.checkedParameter.nodeId !== parameterId){
            setParameterId(bfID.checkedParameter.nodeId)
        }
    },[bfID])

    useEffect(()=>{
        if(bfID
            && bfID.checkedParameter) {

                fetchPatientTreeSelectData(bfID.checkedParameter.nodeId)
        }
    },[parameterId])

    useEffect(()=>{
        if(parameterPatient && parameterPatient[parameterId]){
            fetchParameterPatientsOfBelastungsfall(belastungsfallId, parameterId)
        }
    },[parameterPatient])

    useEffect(()=>{
        if(parameterPatient
            && parameterPatient[parameterId]
            && bfID.checkedParameter.checked === true) {

            const jsxComponents = {}
            Object.values(parameterPatient[parameterId]).flat().forEach(item => {
                jsxComponents[item.id] = (
                    <StyledTreeItem
                        key={item.id}
                        nodeId={item.id}
                        label={item.label}
                    />
                )
            });

            setJSXComponents(jsxComponents)
        }else{
            setJSXComponents(undefined);
        }
    },[parameterPatient, parameterId])

    const fetchPatientTreeSelectData = (parameterId) => {
        if(parameterId !== INITIAL_PARAMETER_ID && loading !== true
            && bfID.activityParameter
            && bfID.activityParameter.activityId
            && bfID.implantActivity
            && bfID.implantActivity.implantId){

            const implantId                 = bfID.implantActivity.implantId;
            const activityId                = bfID.activityParameter.activityId;
            const activityIndentationLevel  = bfID.checkedActivity.indentationlevel;
            const parameterId               = bfID.checkedParameter.nodeId;
            const parameterIndentationLevel = bfID.checkedParameter.indentationlevel;
            fetchParameterPatients( implantId,
                                    activityId,
                                    activityIndentationLevel,
                                    parameterId,
                                    parameterIndentationLevel)
        }
    }

    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();

    const getTreeItemsJSXComponents = (components) => {
        return Object.values(components).map( component => {
            return(
                <PatientTreeSelectItem
                    key={component.props.nodeId}
                    nodeId={component.props.nodeId.toString()}
                    itemName={component.props.label}
                    belastungsfallId={belastungsfallId}
                    parameterId={parameterId}
                />
            )
        });
    };

    return JSXComponents !== undefined
            && bfID
            && bfID.checkedParameter
            && bfID.checkedParameter.checked == true ?
    (
        <OrtholoadAccordion id="orthoload-accordion-patient" name={'Patient'}
                            dataTestID={`${belastungsfallId}-${belastungsfallMetaData[belastungsfallId].name}-patient-accordion`}>

            <div className={classes.container}>
                <TreeView
                    className={classes.root}
                    defaultExpanded={['1']}
                    expanded={expanded2}
                    onNodeToggle={onIconToggle}
                    defaultCollapseIcon={
                        <IconIconWrapper>
                            <ExpandMoreIcon />
                        </IconIconWrapper>
                    }
                    defaultExpandIcon={
                        <IconIconWrapper>
                            <ChevronRightIcon />
                        </IconIconWrapper>
                    }
                    defaultEndIcon={<MoreHorizIcon />}
                >
                    {getTreeItemsJSXComponents(JSXComponents)}
                </TreeView>
            </div>
        </OrtholoadAccordion>

    ):(
        null
        );
}
const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        activityParameter: state.activityParameters.activityParameter,
        belastungsfallMetaData: state.belastungsfallMetaData,
        parameterPatient: state.parameterPatients.parameterPatient,
        loading: state.belastungsfaelle.loading
    }
}
const mapDispatchToProps = dispatch => {
    return{
        fetchParameterPatientsOfBelastungsfall:  (belastungsfallId, implantId) =>
            dispatch(fetchParameterPatientsOfBelastungsfall(belastungsfallId, implantId)),

        fetchParameterPatients: (implantId,
                                 activityId,
                                 activityIndentationLevel,
                                 parameterId,
                                 parameterIndentationLevel) =>
            dispatch(fetchParameterPatients(implantId,
                                            activityId,
                                            activityIndentationLevel,
                                            parameterId,
                                            parameterIndentationLevel)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientTreeSelect)
