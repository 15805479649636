import React, { useRef, useEffect, useState } from 'react';

import {connect} from 'react-redux';
import * as THREE from 'three';
import {STLLoader} from 'three/examples/jsm/loaders/STLLoader';
import {GUI} from './dat.gui.module';
import * as d3 from 'd3';
import {saveVectorScene} from "../../../redux";
import ErrorBoundary from "../../../error/error-boundary";
import makeStyles from '@mui/styles/makeStyles';
import {setupBoneMaterial} from "./VectorGraphicsUtil";
import {interpolateColorsHEX, hexToRgb} from "../../../util/colors";
import {LineSegments} from "./d3.type";

var OrbitControls = require('three-orbit-controls')(THREE)

const useStyles = makeStyles((theme) => ({
    graphicsComponent:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

function VectorThreeComponent(props){
    const {belastungsfallId, belastungsfall, saveVectorScene, applicationVectorScenes} = props;
    const vectorPanelRef = useRef(null);
    const [vectorScene, setVectorScene] = useState();
    var scene, renderer, camera, controls,  total_time = 0,
        ambLight, pLight, pLight2,
        cam_width,
        cam_height;

    useEffect(() => {

        cam_width = vectorPanelRef.current.clientWidth;
        cam_height = vectorPanelRef.current.clientHeight;

        //setup scene
        scene = new THREE.Scene();
        scene.background = new THREE.Color(0xDDDDDD);
        //setup lights
        ambLight = new THREE.AmbientLight(0x404040, 0.8);
        scene.add(ambLight);
        pLight = new THREE.PointLight(0xF6CD8B, 1);
        pLight.position.set(10,15,15);
        scene.add(pLight);
        pLight2 = new THREE.PointLight(0xF6CD8B, 0.5);
        pLight2.position.set(0,15,-15);
        scene.add(pLight2);
        //setup camera
        camera = new THREE.PerspectiveCamera(45, cam_width/cam_height, 1, 1000);
        camera.position.set(15, 5, 20);
        scene.add(camera);

        var helper = new THREE.AxesHelper(7);
        var colors = helper.geometry.attributes.color;
        //change colors to Orthoload axes colors
        colors.setXYZ(0, 0, 1, 0);
        colors.setXYZ(1, 0, 1, 0);
        colors.setXYZ(2, 1, 0, 0);
        colors.setXYZ(3, 1, 0, 0);
        colors.setXYZ(4, 0, 0, 1);
        colors.setXYZ(5, 0, 0, 1);
        scene.add(helper);

        const material = setupBoneMaterial();
        let loader = new STLLoader();
        loader.load( 'right_femur.stl', function ( geometry ) {
            let bone = new THREE.Mesh( geometry, material );
            //set position on coordinate origin
            bone.position.set(3,5.25,2.25);
            //rotate 90°
            bone.rotation.x = -Math.PI/2;
            scene.add( bone );
        });

        //setup renderer and controls
        renderer = new THREE.WebGLRenderer({antialias: true, flatShading: false});
        renderer.setSize(cam_width, cam_height);


        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableZoom = true;
        //move view field up
        controls.target.set(0,1,0);
        //setup Controls
        const gui = new GUI({
            autoPlace:false,
        });
        gui.domElement.id = 'gui';

        vectorPanelRef.current.appendChild(renderer.domElement);
        vectorPanelRef.current.appendChild(gui.domElement);

        var show_all = {show_all_vectors: function(){
                for( let i = scene.children.length - 1; i >= 6; i--) {
                    scene.children[i].visible = true;
                }
                d3.select('.circle-cursors').attr('time_pos', total_time);
            }}
        var reset_view = {reset_view: function (){
                controls.reset();
                controls.update();
            }}

        setVectorScene(scene);

        const AppearanceFolder = gui.addFolder('Appearance')
        // AppearanceFolder.add(skalierung, 'vector_scale', 0.01, 0.1)
        //     .onChange(vector_function());
        AppearanceFolder.add(material, 'opacity', 0.4, 1.0);
        AppearanceFolder.open();
        const ViewFolder = gui.addFolder('View');
        ViewFolder.add(show_all,'show_all_vectors');
        ViewFolder.add(reset_view, 'reset_view');
        ViewFolder.open();
        gui.close();

        var animate = function () {
            requestAnimationFrame( animate );
            controls.update();
            renderer.render( scene, camera );
        }

        let onWindowResize = function () {
            if(vectorPanelRef.current !== null) {
                cam_width = vectorPanelRef.current.clientWidth;
                cam_height = vectorPanelRef.current.clientHeight;
                renderer.setSize(cam_width, cam_height);
                camera.aspect = cam_width / cam_height;
                camera.updateProjectionMatrix();
            }
        }

        window.addEventListener("resize", onWindowResize, false);
        animate();

    }, []);


    useEffect(()=>{
        if(vectorScene && applicationVectorScenes[belastungsfallId] === undefined) {
            saveVectorScene(belastungsfallId, vectorScene);

        }else if(vectorScene !== undefined) {
            vector_function()
        }
    }, [belastungsfall, vectorScene])

    function vector_function(){
        if (belastungsfall[belastungsfallId].slicesBW
            && belastungsfall[belastungsfallId].slicesBW[0]) {
            //delete old LINESegments if they exist
            vectorScene.children = Object.values(vectorScene.children).filter(el => el.type !== LineSegments)

            //draw new vectors
            var points = [];
            let v_x, v_y, v_z;
            let max_Fres_value = Math.max.apply(Math, belastungsfall[belastungsfallId].slicesBW[3].values.map(function (o) {
                return o.measurement
            }));
            let startColorHex = "#0088cc"
            let endColorHex = "#FF0000"
            let startRGB = hexToRgb(startColorHex)
            let endRGB = hexToRgb(endColorHex)
            let vectorColorGradientArray = interpolateColorsHEX(startRGB, endRGB, Math.floor(max_Fres_value))

            //add vector for each value -> points array has to be reseted to enable different colors for each vector
            let v_x_min = 10000
            let v_y_min = 10000
            let v_z_min = 10000
            for(let i = 0; i < belastungsfall[belastungsfallId].slicesBW[1].values.length; ++i){
                if(v_x_min > belastungsfall[belastungsfallId].slicesBW[0].values[i].measurement){
                    v_x_min = belastungsfall[belastungsfallId].slicesBW[0].values[i].measurement
                }
                if(v_y_min > belastungsfall[belastungsfallId].slicesBW[1].values[i].measurement){
                    v_y_min = belastungsfall[belastungsfallId].slicesBW[1].values[i].measurement
                }
                if(v_z_min > belastungsfall[belastungsfallId].slicesBW[2].values[i].measurement){
                    v_z_min = belastungsfall[belastungsfallId].slicesBW[2].values[i].measurement
                }
            }
            let v_norm_min = Math.sqrt(Math.pow(v_x_min,2) + Math.pow(v_y_min,2) + Math.pow(v_z_min,2))

            belastungsfall[belastungsfallId].slicesBW[0].values.forEach((value, index) => {
                points = [];

                v_x = belastungsfall[belastungsfallId].slicesBW[0].values[index].measurement * 1/v_norm_min
                v_y = belastungsfall[belastungsfallId].slicesBW[1].values[index].measurement * 1/v_norm_min
                v_z = belastungsfall[belastungsfallId].slicesBW[2].values[index].measurement * 1/v_norm_min
                let v_norm = Math.sqrt(Math.pow(v_x,2) + Math.pow(v_y,2) + Math.pow(v_z,2))

                let scale_factor = 8
                v_x = v_x * scale_factor
                v_y = v_y * scale_factor
                v_z = v_z * scale_factor

                let v_offset = 0.3
                let v_x_0 = v_x * v_offset/v_norm
                let v_y_0 = v_y * v_offset/v_norm
                let v_z_0 = v_z * v_offset/v_norm

                points.push(new THREE.Vector3( v_x_0, Math.abs(v_z_0), v_y_0));
                //XZY intentional because of bone rotation
                points.push(new THREE.Vector3(v_x, Math.abs(v_z), v_y));
                const vectGeometry = new THREE.BufferGeometry().setFromPoints(points);
                const vectMaterial = new THREE.LineBasicMaterial({
                    color: vectorColorGradientArray[ Math.floor(belastungsfall[belastungsfallId]
                                                        .slicesBW[3].values[index].measurement)]});
                var Fline = new THREE.LineSegments(vectGeometry, vectMaterial);
                vectorScene.add(Fline);

            });
            saveVectorScene(belastungsfallId, vectorScene);
        }
    }
    return (
        <ErrorBoundary>
            <div ref={vectorPanelRef} style={{height: "100%", width:"100%"}}/>
        </ErrorBoundary>
    )
}

const mapStateToProps = (state) =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        applicationVectorScenes: state.vectorScenes
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        saveVectorScene: (belastungsfallId, vectorScene)=> dispatch(saveVectorScene(belastungsfallId, vectorScene))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VectorThreeComponent);
