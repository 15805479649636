import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import {StyledTreeItem} from "../common/TreeSelectionItem";
import Typography from "@mui/material/Typography";
import {
    checkBelastungsfallFileData,
    fetchFileDatas,
} from "../../../../redux";
import {connect} from "react-redux";

function DataTreeSelectItem(props) {

    const { nodeId,
            itemName,
            belastungsfallId,
            children,
            belastungsfall,
            checkBelastungsfallFileData,
            fetchFileDatas } = props;

    let bfID = belastungsfall[belastungsfallId];
    const [checked, setChecked] = useState(false);


    useEffect(() => {
        if(bfID && bfID.checkedData && bfID.checkedData[nodeId]){
            setChecked(bfID.checkedData[nodeId].checked)
        }else{
            setChecked(false);
        }
    }, [belastungsfall])

    const handleCheckboxClicked = event => {
        checkBelastungsfallFileData(nodeId, belastungsfallId, event.target.checked)
    };


    const checkboxLabel = (check)=>{
        return(
        <div style={{ display: 'flex', alignItems: 'center' , justifyContent : 'flex-start'}}>
            <Typography>
                <Checkbox
                    id={nodeId}
                    color="primary"
                    onClick={e => handleCheckboxClicked(e)}
                    checked={check}
                />
                {itemName.split('.')[1]}
            </Typography>
        </div>
        )
    }

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={checkboxLabel(checked)}
        >
            {children}
        </StyledTreeItem>
    )
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        checkBelastungsfallFileData:(nodeId,
                                     belastungsfallId,
                                     checked) =>
                dispatch(checkBelastungsfallFileData(   nodeId,
                                                        belastungsfallId,
                                                        checked)),
        fetchFileDatas:( implantId,
                         activityId,
                         activityIndentationLevel,
                         parameterId,
                         parameterIndentationLevel,
                         patientId,
                         fileId) =>
                dispatch(fetchFileDatas(implantId,
                                        activityId,
                                        activityIndentationLevel,
                                        parameterId,
                                        parameterIndentationLevel,
                                        patientId,
                                        fileId)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTreeSelectItem)
