import * as d3 from 'd3';

let video = {},
    videoRef;

video.create = (vRef) =>{
    videoRef = vRef;
    videoRef.style.width = "100%";

    //rescale on window resolution change
    window.addEventListener('resize', function () {

        // videoRef.style.width = `${videoRef.clientWidth * size_correction_factor}`;
        videoRef.style.width = "100%";
    })
}

video.parseVideoData = (filepath) => {
    videoRef.setAttribute('src', filepath);
}

//update Patient Video
video.updateFrame = (belastungsfallId) => {
    //IMPORTANT NOTE!! Only works lagg-free with small keyframe interval!
    document.getElementById(`video${belastungsfallId}`).currentTime
        = +d3.select('.circle-cursors').attr('time_pos');
}


export default video;
