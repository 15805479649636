import React from 'react';
import Chart from "../chart/Chart";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Measurements from "../measurements/Measurements";
import VectorThreeComponent from "../vectors/VectorThreeComponent";
import VideoComponent from "../video/VideoComponent";
import MeasureingInformation from "../measurementInformation/MeasureingInformation";
import ErrorBoundary from "../../../error/error-boundary";
import {isChartDataReady} from "../../../util/helpingFunctions";
import {connect} from "react-redux";
import ShowChartRounded from '@mui/icons-material/ShowChartRounded';
import VideocamRounded from '@mui/icons-material/VideocamRounded';
import ThreeDRotationRounded from '@mui/icons-material/ThreeDRotationRounded';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme) => ({

    panelContainer: {
        width: '48vw',
    },
    panelContent:{
        width: '100%',
        paddingRight: '1vw',
        paddingLeft: '1vw',
    },
    chartContainer: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0, 0, 3),
    },
    chartIcon: {
    },
    measurementsContainer: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 2, 0),
    },
    video: {
        backgroundColor: theme.palette.background.paper,
        height: '40vh',
        overflowY: 'hidden'
    },
    vector: {
        backgroundColor: theme.palette.background.paper,
        height: '40vh',
        width: '24vw',
        overflow: 'hidden',
    },
    dataPanelSkeleton:{
        width: '48vw',
        height: '20vh',
    },
    measureingInformation:{
    },
}));


function DataPanel(props) {
    const classes = useStyles();
    const {belastungsfallId} = props
    return (
        <ErrorBoundary>
            {
                isChartDataReady(props) === true ? (
                    <div className={classes.panelContainer}>
                        <Grid container spacing={1} id={"mainGridContainer"} >
                            <div className={classes.panelContent}>
                                <Grid item>
                                    <Paper>
                                        {
                                            isChartDataReady(props) === true ?
                                                <Chart belastungsfallId={belastungsfallId} />
                                                : <ShowChartRounded className={classes.chartIcon}/>
                                        }
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Paper className={classes.measurementsContainer}>
                                        <Measurements belastungsfallId={belastungsfallId}/>
                                    </Paper>
                                </Grid>

                                <Paper>
                                    <Grid container spacing={1} >
                                        <Grid item lg={6}  xs={6} >
                                            <Paper className={classes.vector}>
                                                {
                                                    isChartDataReady(props) === true ?
                                                        < VectorThreeComponent
                                                            belastungsfallId={belastungsfallId}/>
                                                        : <ThreeDRotationRounded/>
                                                }
                                            </Paper>
                                        </Grid>

                                        <Grid item lg={6}  xs={6} >
                                            <Paper className={classes.video}>
                                                {
                                                    isChartDataReady(props) === true ?
                                                        <VideoComponent belastungsfallId={belastungsfallId}/>
                                                        : <VideocamRounded/>
                                                }
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Grid  item>
                                    <Grid item>

                                    </Grid>
                                    <Grid item >
                                        {
                                            isChartDataReady(props) === true ?
                                                <MeasureingInformation belastungsfallId={belastungsfallId}
                                                                       className={classes.measureingInformation}/>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </div>
                    )

                :   (<>
                        <Skeleton>
                            <div className={classes.dataPanelSkeleton}/>
                        </Skeleton>
                        <Skeleton>
                            <div className={classes.dataPanelSkeleton}/>
                        </Skeleton>
                        <Skeleton>
                            <div className={classes.dataPanelSkeleton}/>
                        </Skeleton>
                        <Skeleton>
                            <div className={classes.dataPanelSkeleton}/>
                        </Skeleton>
                        <Skeleton>
                            <div className={classes.dataPanelSkeleton}/>
                        </Skeleton>
                    </>)
            }
        </ErrorBoundary>
    );
}
const mapStateToProps= (state)=>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        dataPathData: state.fileDatas.dataPathData,
    }
}

const mapDispatchToProps = (dispatch) =>{
    return{
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DataPanel);
