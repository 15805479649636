import React, { useEffect, useState} from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileTreeSelectItem from './FileTreeSelectItem';
import OrtholoadAccordion from "../../layout/OrtholoadAccordion";
import {StyledTreeItem, useStyles} from '../common/TreeSelectionItem'
import {useIconToggleClick} from "useseparatetoggleclick";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {
    INITIAL_PARAMETER_ID,
    INITIAL_PATIENT_ID,
} from '../../../../util/constants'
import {
    fetchPatientFilesOfBelastungsfall,
    fetchPatientFiles,
} from "../../../../redux";

FileTreeSelect.propTypes = {
    customClasses: PropTypes.any,
};

function FileTreeSelect(props) {


    const { customClasses,
            belastungsfall,
            belastungsfallId,
            belastungsfallMetaData,
            patientFile,
            fetchPatientFiles,
            fetchPatientFilesOfBelastungsfall,
            loading } = props;

    let classes = useStyles();
    let bfID = belastungsfall[belastungsfallId];
    const [JSXFileComponents, setJSXFileComponents] = useState(undefined)
    const [JSXAdditionalFileComponents, setJSXAdditionalFileComponents] = useState(undefined)
    const [patientId, setPatientId] = useState(INITIAL_PARAMETER_ID);
    if(customClasses){
        classes = customClasses;
    }

    useEffect(()=>{
        if(bfID
            && bfID.checkedPatient
            && bfID.checkedPatient.nodeId !== patientId){
            setPatientId(bfID.checkedPatient.nodeId)
        }
    },[bfID])

    useEffect(()=>{
        if(bfID
            && bfID.checkedPatient) {

                fetchFileTreeSelectData(bfID.checkedPatient.nodeId)
        }
    },[patientId])

    useEffect(()=>{
        if(patientFile && patientFile[patientId]){
            fetchPatientFilesOfBelastungsfall(belastungsfallId, patientId)
        }
    },[patientFile])

    useEffect(()=>{
        if(patientFile
            && patientFile[patientId]
            && bfID.checkedPatient.checked === true) {


            const jsxFileComponents = {}
            const jsxAdditionalFileComponents = {}
            Object.values(patientFile[patientId].files).flat().forEach(item => {
                jsxFileComponents[item.id] = (
                    <StyledTreeItem
                        key={item.id}
                        nodeId={item.id}
                        label={item.label}
                    />
                )
            });
            setJSXFileComponents(jsxFileComponents)
            if(Object.values(patientFile[patientId].additionalFiles).flat() !== []) {
                Object.values(patientFile[patientId].additionalFiles).flat().forEach(item => {
                    jsxAdditionalFileComponents[item.id] = (
                        <StyledTreeItem
                            key={item.id}
                            nodeId={item.id}
                            label={item.label}
                        />
                    )
                });
                setJSXAdditionalFileComponents(jsxAdditionalFileComponents)
            }
        }else{
            setJSXAdditionalFileComponents(undefined);
            setJSXFileComponents(undefined);
        }
    },[patientFile, patientId])

    const fetchFileTreeSelectData = (patientId) => {
        if(patientId !== INITIAL_PATIENT_ID && loading !== true
            && bfID.activityParameter
            && bfID.activityParameter.activityId
            && bfID.implantActivity
            && bfID.implantActivity.implantId){

            const implantId                 = bfID.implantActivity.implantId;
            const activityId                = bfID.activityParameter.activityId;
            const activityIndentationLevel  = bfID.checkedActivity.indentationlevel;
            const parameterId               = bfID.checkedParameter.nodeId;
            const parameterIndentationLevel = bfID.checkedParameter.indentationlevel;

            fetchPatientFiles(  implantId,
                                activityId,
                                activityIndentationLevel,
                                parameterId,
                                parameterIndentationLevel,
                                patientId)
        }
    }

    const {
        expanded: expanded2,
        onNodeToggle: onIconToggle,
        IconWrapper: IconIconWrapper
    } = useIconToggleClick();

    const getTreeItemsJSXComponents = (components) => {
        return Object.values(components).map( component => {
            return(
                <FileTreeSelectItem
                    key={component.props.nodeId}
                    nodeId={component.props.nodeId.toString()}
                    itemName={component.props.label}
                    belastungsfallId={belastungsfallId}

                />
            )
        });
    };

    return (JSXFileComponents || JSXAdditionalFileComponents)
    && bfID
    && bfID.checkedPatient
    && bfID.checkedPatient.checked == true?
        (
            <OrtholoadAccordion id="orthoload-accordion-file" name={'File'}
                                dataTestID={`${belastungsfallId}-${belastungsfallMetaData[belastungsfallId].name}-file-accordion`}>
                <div className={classes.container}>
                    <TreeView
                        className={classes.root}
                        defaultExpanded={['1']}
                        expanded={expanded2}
                        onNodeToggle={onIconToggle}
                        defaultCollapseIcon={
                            <IconIconWrapper>
                                <ExpandMoreIcon />
                            </IconIconWrapper>
                        }
                        defaultExpandIcon={
                            <IconIconWrapper>
                                <ChevronRightIcon />
                            </IconIconWrapper>
                        }
                        defaultEndIcon={<MoreHorizIcon />}
                    >
                        {JSXFileComponents ?
                            getTreeItemsJSXComponents(JSXFileComponents)
                            : ('No Additional Files')
                        }
                        {/*{'Additional files'}*/}
                        {/*<Divider />*/}
                        {/*{JSXAdditionalFileComponents ?*/}
                        {/*    getTreeItemsJSXComponents(JSXAdditionalFileComponents)*/}
                        {/*    : ('No Additional Files')*/}
                        {/*}*/}
                    </TreeView>
                </div>
            </OrtholoadAccordion>
        ):  (
            null
        );
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
        belastungsfallMetaData: state.belastungsfallMetaData,
        patientFile: state.patientFiles.patientFile,
        loading: state.patientFiles.loading
    }
}

const mapDispatchToProps = dispatch => {
    return{
        fetchPatientFilesOfBelastungsfall:  (belastungsfallId, implantId) =>
            dispatch(fetchPatientFilesOfBelastungsfall(belastungsfallId, implantId)),

        fetchPatientFiles: ( implantId,
                             activityId,
                             activityIndentationLevel,
                             parameterId,
                             parameterIndentationLevel,
                             patientId) =>
            dispatch(fetchPatientFiles(implantId,
                            activityId,
                            activityIndentationLevel,
                            parameterId,
                            parameterIndentationLevel,
                            patientId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileTreeSelect)
