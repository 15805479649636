import * as d3 from "d3";
import {LineSegments, Mesh} from "./d3.type";

export function vect_update(vectorScenes, belastungsfallId, chartDataStore){
    const total_time = chartDataStore.state.slicesBW[3].values[chartDataStore.state.slicesBW[3].values.length - 1].time
    const time = +d3.select('.circle-cursors').attr('time_pos')
    let progress_percent = time / (total_time / 100)
    let current_value = Math.round((chartDataStore.state.slicesBW[3].values.length - 1) * progress_percent / 100)
    if(current_value % 2 === 0 ) {
        if(chartDataStore.state.slicesBW && chartDataStore.state.slicesBW[3]) {

            //add offset of 6 bc. elements 0-6 are the bone, texture and lights
            //for each: < mouse value: visible false, > mouse value: visible true
            vectorScenes[belastungsfallId].vectorScene.children.forEach((value, index) => {

                if (vectorScenes[belastungsfallId].vectorScene.children[index].type === LineSegments) {
                    if (index < current_value && index > current_value - 30) {
                        vectorScenes[belastungsfallId].vectorScene.children[index].visible = true
                    } else {
                        vectorScenes[belastungsfallId].vectorScene.children[index].visible = false
                    }
                } else {
                    vectorScenes[belastungsfallId].vectorScene.children[index].visible = true
                }
            });
        }
    }
}
