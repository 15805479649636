import axios from 'axios';
import {
    FETCH_SEARCH_FAILURE,
    FETCH_SEARCH_SUCCESS,
    FETCH_SEARCH_REQUEST
} from "./FullTextSearch.type";
import {BASE_URL, SEARCH} from "../../util/urls";
import {config} from "../../config/axiosConfig";

const requestURL = (term) => {
    return BASE_URL+SEARCH+`term=${term}`;
}

export const doFullTextSearch = (term) => {
    // wenn bealstungfall schon im store, dann nicht noch einen Api call
    return (dispatch) => {
        // set loading state
        dispatch(fetchSearchRequest())
        axios.get(requestURL(term), config)
            .then(response => {
                const data = response.data
                dispatch(fetchSearchSuccess(data))
            })
            .catch(error => {
                dispatch(fetchSearchFailure(error.message))
            })
    };
}

export const fetchSearchRequest = () => {
    return{
        type: FETCH_SEARCH_REQUEST
    }
}
export const fetchSearchFailure = (error) => {
    return {
        type: FETCH_SEARCH_FAILURE,
        payload: error
    }
}
export const fetchSearchSuccess = (data) => {
    return{
        type: FETCH_SEARCH_SUCCESS,
        payload: {data}
    }
}
