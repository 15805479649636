import React from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'react-dropdown-tree-select/dist/styles.css'
import Belastungsfaelle from "./components/drawer/layout/Belastungsfaelle";
import KonfigurationKategory from "./components/drawer/layout/KonfigurationKategory";
import {useStyles} from './styles/App.styles'
import DashboardMain from "./components/main/dashboardMain/DashboardMain";
import {IntroductionModal} from "./components/main/tutorialModal/IntroductionModal";
import {Paper} from "@mui/material";

export default function MiniDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
        >
          <Toolbar>
            <ChevronRightIcon
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
            />

            <Typography className={classes.ortholoadFontColor} variant="h5" >
                workHEALTH -
            </Typography>
            <Typography className={classes.dataFontColor} variant="h5">
              Data
            </Typography>
            <Typography className={classes.dashboardFontColor} variant="h5" >
              Dashboard
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
          <div className={classes.toolbar}>
            <IconButton data-testid={"drawer-handle-chevron"} onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <KonfigurationKategory id="belastungsfall-kategory"
                                 hide={!open}
                                 configurationName={'Case Studies'}
                                 data-testid={"case-studies-konfiguration-category"}>
            <Belastungsfaelle />
          </KonfigurationKategory>
        </Drawer>
        <Paper className={clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })
        }/>
        <main id={'main-container'}>
              <div className={classes.toolbar}/>
              <DashboardMain />
              <IntroductionModal />
        </main>
      </div>
  );
}
