import axios from 'axios';
import {
    FETCH_PATIENT_FILE_FAILURE,
    FETCH_PATIENT_FILE_SUCCESS,
    FETCH_PATIENT_FILE_REQUEST
} from "./PatientFile.type";
import {BASE_URL, FILES} from "../../util/urls";
import {config} from "../../config/axiosConfig";

const requestURL = (implantId,
                    activityId,
                    activityIndentationLevel,
                    parameterId,
                    parameterIndentationLevel,
                    patientId) => {
    return BASE_URL+FILES+`implantId=${implantId}&activityId=${activityId}&activityIndentationLevel=${activityIndentationLevel}&parameterId=${parameterId}&parameterIndentationLevel=${parameterIndentationLevel}&patientId=${patientId}`;
}

export const fetchPatientFiles = ( implantId,
                                   activityId,
                                   activityIndentationLevel,
                                   parameterId,
                                   parameterIndentationLevel,
                                   patientId) => {
    // wenn bealstungfall schon im store, dann nicht noch einen Api call
    return (dispatch) => {
        // set loading state
        dispatch(fetchPatientFileRequest())
        axios.get(requestURL(   implantId,
                                activityId,
                                activityIndentationLevel,
                                parameterId,
                                parameterIndentationLevel,
                                patientId), config)
            .then(response => {
                const data = response.data
                dispatch(fetchPatientFileSuccess(data.data, patientId))
            })
            .catch(error => {
                dispatch(fetchPatientFileFailure(error.message))
            })
    };
}

export const fetchPatientFileRequest = () => {
    return{
        type: FETCH_PATIENT_FILE_REQUEST
    }
}
export const fetchPatientFileFailure = (error) => {
    return {
        type: FETCH_PATIENT_FILE_FAILURE,
        payload: error
    }
}
export const fetchPatientFileSuccess = (data, patientId) => {
    return{
        type: FETCH_PATIENT_FILE_SUCCESS,
        payload: {data, patientId}
    }
}
