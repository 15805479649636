import axios from 'axios';
import {
    FETCH_ACTIVITY_PARAMETER_REQUEST,
    FETCH_ACTIVITY_PARAMETER_SUCCESS,
    FETCH_ACTIVITY_PARAMETER_FAILURE,
} from "./ActivityParameter.type";
import {
    BASE_URL,
    PARAMETERS
} from "../../util/urls";
import {config} from "../../config/axiosConfig";

const requestURL = (implantId,
                    activityId,
                    activityIndentationLevel) => {
    return BASE_URL+PARAMETERS+`implantId=${implantId}&activityId=${activityId}&activityIndentationLevel=${activityIndentationLevel}`;
}

export const fetchActivityParameters = (  implantId,
                                          activityId,
                                          activityIndentationLevel) => {
    return (dispatch) => {
        dispatch(fetchActivityParametersRequest())
        axios.get(requestURL(   implantId,
                                activityId,
                                activityIndentationLevel), config)
            .then(response => {
                const data = response.data
                dispatch(fetchActivityParametersSuccess(data.data, activityId))
            })
            .catch(error => {
                dispatch(fetchActivityParametersFailure(error.message))
            })
    };
}

export const fetchActivityParametersRequest = () => {
    return{
        type: FETCH_ACTIVITY_PARAMETER_REQUEST
    }
}
export const fetchActivityParametersFailure = (error) => {
    return {
        type: FETCH_ACTIVITY_PARAMETER_FAILURE,
        payload: error
    }
}
export const fetchActivityParametersSuccess = (data, activityId) => {
    return{
        type: FETCH_ACTIVITY_PARAMETER_SUCCESS,
        payload: {data, activityId}
    }
}

