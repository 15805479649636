import React, {useEffect, useState} from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ActivityTreeSelect from "../treeSelect/activity/ActivityTreeSelect";
import {
    GELENK_SELECTION,
    INITIAL_GELENK_NAME
} from '../../../util/constants'
import {connect} from "react-redux";
import ParameterTreeSelect from "../treeSelect/parameter/ParameterTreeSelect";
import PatientTreeSelect from "../treeSelect/patient/PatientTreeSelect";
import FileTreeSelect from "../treeSelect/file/FileTreeSelect";
import DataTreeSelect from "../treeSelect/data/DataTreeSelect";
import {
    setBelastungsfallMetaDataName,
    selectBelastungsfallImplant,
} from "../../../redux";
import FullTextSearchBar from "../../main/fullTextSearch/FullTextSearchBar";

function getStyles(name, jointName, theme) {
    return {
        fontWeight:
            jointName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(0.3),

    },

    helperText: {
        whiteSpace: 'pre-wrap',
    }
}));

function BelastungsfallSelect({ belastungsfallId,
                                  belastungsfallMetaData,
                                  setBelastungsfallMetaDataName,
                                  selectBelastungsfallImplant}) {

    const classes = useStyles();
    const theme = useTheme();
    const [jointName, setJointName] = useState(INITIAL_GELENK_NAME)

    useEffect(() => {
        if(belastungsfallMetaData[belastungsfallId].name) {
            setJointName(belastungsfallMetaData[belastungsfallId].name)
        }
    }, [belastungsfallMetaData]);


    function setConfigForBelastungsfall(belastungsfallId, jointName) {
        setBelastungsfallMetaDataName(belastungsfallId, jointName)
        selectBelastungsfallImplant(belastungsfallId)
    }

    const handleChange = (event) => {
        setJointName(event.target.value)
        setConfigForBelastungsfall(belastungsfallId, event.target.value)
    };

    return jointName ?  (
        <>
            <FullTextSearchBar belastungsfallId={belastungsfallId}/>
            <FormControl className={classes.formControl}>
                <InputLabel shrink>
                    Case Study: {jointName}
                </InputLabel>
                <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    data-testid={`${belastungsfallId}-belastungsfall-select-input`}
                    onChange={handleChange}
                    displayEmpty
                    value={jointName}
                >
                    {GELENK_SELECTION
                        .map((belastungsFall) => (
                            <MenuItem
                                key={belastungsFall.reqId}
                                value={belastungsFall.name}
                                style={getStyles(belastungsFall.name, jointName, theme)}
                                data-testid={`${belastungsfallId}-belastungsfall-item-${belastungsFall.name}`}
                            >
                                {belastungsFall.name}
                            </MenuItem>
                        ))}
                </Select>
                <FormHelperText className={classes.helperText}>
                    Please select a case study to exhibit the data. Once you chose a case,
                    more selections in the below drop down menues will be available.
                </FormHelperText>
            </FormControl>
            <ActivityTreeSelect
                jointName={jointName}
                belastungsfallId={belastungsfallId}
            />
            <ParameterTreeSelect belastungsfallId={belastungsfallId}/>
            <PatientTreeSelect belastungsfallId={belastungsfallId}/>
            <FileTreeSelect belastungsfallId={belastungsfallId}/>
            <DataTreeSelect belastungsfallId={belastungsfallId}/>
        </>
    ): (<CircularProgress />);
};


const mapStateToProps = state =>{
    return{
        belastungsfallMetaData: state.belastungsfallMetaData,

    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        setBelastungsfallMetaDataName: (newBelastungsfallId, name) => dispatch(setBelastungsfallMetaDataName(newBelastungsfallId, name)),
        selectBelastungsfallImplant: (newBelastungsfallId) => dispatch(selectBelastungsfallImplant(newBelastungsfallId)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BelastungsfallSelect)
