import {
    FETCH_PATIENT_FILE_REQUEST,
    FETCH_PATIENT_FILE_SUCCESS,
    FETCH_PATIENT_FILE_FAILURE
} from "./PatientFile.type";

const initialState = {
    loading: false,
    patientFiles: {},
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PATIENT_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FETCH_PATIENT_FILE_SUCCESS:
            const patientId = action.payload.patientId;
            const patientFile = action.payload.data;
            return {
                loading: false,
                patientFile: {
                    ...state.patientFile,
                    [patientId]: {
                        ...patientFile
                    },
                },
                error: ''
            }
        case FETCH_PATIENT_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default: return state
    }
}
// link to the rootReducer
export default reducer
