import {
    SET_BELASTUNGSFALL_JOINT_NAME,
    DELETE_BELASTUNGSFALL_META_DATA,
    SET_BELASTUNGSFALL_ACTIVITY_NAME,
    SET_BELASTUNGSFALL_PARAMETER_NAME,
    SET_BELASTUNGSFALL_FILE_NAME,
} from "./BelastungsfallMetaData.type";

export const setBelastungsfallMetaDataName = (newBelastungsfallId, jointName) =>{
    return {
        type: SET_BELASTUNGSFALL_JOINT_NAME,
        payload: {newBelastungsfallId, jointName}
    }
}

export const setBelastungsfallMetaDataActivityName = (newBelastungsfallId, activityName) =>{
    return {
        type: SET_BELASTUNGSFALL_ACTIVITY_NAME,
        payload: {newBelastungsfallId, activityName}
    }
}
export const setBelastungsfallMetaDataParameterName = (newBelastungsfallId, parameterName) =>{
    return {
        type: SET_BELASTUNGSFALL_PARAMETER_NAME,
        payload: {newBelastungsfallId, parameterName}
    }
}
export const setBelastungsfallMetaDataFileName = (newBelastungsfallId, fileName) =>{
    return {
        type: SET_BELASTUNGSFALL_FILE_NAME,
        payload: {newBelastungsfallId, fileName}
    }
}
export const deleteBelastungsfallMetaData = (belastungsfallId) =>{
    return {
        type: DELETE_BELASTUNGSFALL_META_DATA,
        payload: {belastungsfallId}
    }
}
