import Checkbox from '@mui/material/Checkbox';
import React, {useEffect, useState} from "react";
import {StyledTreeItem} from "../common/TreeSelectionItem";
import Typography from "@mui/material/Typography";
import {
    checkBelastungsfallActivityParameter,
    setBelastungsfallMetaDataParameterName,
    fetchParameterPatients
} from "../../../../redux";
import {connect} from "react-redux";

function ParameterTreeSelectItem(props) {

    const { nodeId,
            itemName,
            indentationlevel,
            belastungsfallId,
            children,
            belastungsfall,
            checkBelastungsfallActivityParameter,
            setBelastungsfallMetaDataParameterName,
            fetchParemterPatients} = props

    let bfID = belastungsfall[belastungsfallId]
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        if(bfID && bfID.checkedParameter && bfID.checkedParameter.nodeId === nodeId){
            setChecked(bfID.checkedParameter.checked)
        }else{
            setChecked(false);
        }
        // else{
        //     setChecked(false);
        // }
    }, [belastungsfall])

    const handleCheckboxClicked = event => {
        checkBelastungsfallActivityParameter(nodeId, itemName, belastungsfallId, indentationlevel, event.target.checked)
        setBelastungsfallMetaDataParameterName(belastungsfallId, itemName);
        // now fetch the parameters of the activity.

        const implantId                 = bfID.implantActivity.implantId
        const activityId                = bfID.checkedActivity.nodeId
        const activityIndentationLevel  = bfID.checkedActivity.indentationlevel
        const parameterId               = nodeId
        const parameterIndentationLevel = indentationlevel
        fetchParameterPatients( implantId,
                                activityId,
                                activityIndentationLevel,
                                parameterId,
                                parameterIndentationLevel)
    };


    const checkboxLabel = (check)=>{
        return(
        <div style={{ display: 'flex', alignItems: 'center' , justifyContent : 'flex-start'}}>
            <Typography>
                <Checkbox
                    data-testid={`parameter-checkbox-${nodeId}-${belastungsfallId}`}
                    id={nodeId}
                    color="primary"
                    onClick={e => handleCheckboxClicked(e)}
                    checked={check}
                />
                {itemName}
            </Typography>
        </div>
        )
    }

    return (
        <StyledTreeItem
            nodeId={nodeId}
            label={checkboxLabel(checked)}
            indentationlevel={indentationlevel}
        >
            {children}
        </StyledTreeItem>
    )
}

const mapStateToProps = state =>{
    return{
        belastungsfall: state.belastungsfaelle.belastungsfall,
    }
}
const mapDispatchToProps = dispatch => {
    return{
        checkBelastungsfallActivityParameter: (nodeId, label, belastungsfallId, indentationlevel, checked) =>
                dispatch(checkBelastungsfallActivityParameter(  nodeId, label, belastungsfallId, indentationlevel, checked)),
        setBelastungsfallMetaDataParameterName: (belastungsfallId, parameterName) =>
                dispatch(setBelastungsfallMetaDataParameterName(belastungsfallId, parameterName)),
        fetchParameterPatients: (implantId, activityId, activityIndentationLevel,
                                 parameterId, parameterIndentationLevel) =>
                dispatch(fetchParameterPatients(implantId, activityId, activityIndentationLevel,
                    parameterId, parameterIndentationLevel)),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParameterTreeSelectItem)
