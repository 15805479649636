import axios from 'axios';
import {
    FETCH_IMPLANT_ACTIVITY_FAILURE,
    FETCH_IMPLANT_ACTIVITY_SUCCESS,
    FETCH_IMPLANT_ACTIVITY_REQUEST,
} from "./ImplantActivity.type";
import {
    BASE_URL,
    ACTIVITIES
} from "../../util/urls";
import {config} from "../../config/axiosConfig";

const requestURL = (implantId) => {
    return BASE_URL+ACTIVITIES+`implantId=${implantId}`;
}

export const fetchImplantActivites = (implantId) => {
    // wenn Implantat schon im store, dann nicht noch einen Api call
    return (dispatch) => {
        // set loading state
        dispatch(fetchImplantActivitesRequest())
        axios.get(requestURL(implantId), config)
            .then(response => {
                const data = response.data
                dispatch(fetchImplantActivitesSuccess(data.data, implantId))
            })
            .catch(error => {
                dispatch(fetchImplantActivitesFailure(error.message))
            })
    };
}

export const fetchImplantActivitesRequest = () => {
    return{
        type: FETCH_IMPLANT_ACTIVITY_REQUEST
    }
}
export const fetchImplantActivitesFailure = (error) => {
    return {
        type: FETCH_IMPLANT_ACTIVITY_FAILURE,
        payload: error
    }
}
export const fetchImplantActivitesSuccess = (data, implantId) => {
    return{
        type: FETCH_IMPLANT_ACTIVITY_SUCCESS,
        payload: {data, implantId}
    }
}
