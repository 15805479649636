import {
    AKF_FILE_ENDING,
    COF_FILE_ENDING,
    EOF_FILE_ENDING,
    IOF_FILE_ENDING,
    FILE_TYPE_AKF,
    FILE_TYPE_UNKOWN,
    UNKOWN_FILE_ENDING, AVI_FILE_ENDING, INITIAL_ZERO
} from "./constants";
import * as _ from "lodash";
import {useEffect, useState} from "react";
import {TwoDimHashMap} from "./hashmap";
import {StyledTreeItem} from "../components/drawer/treeSelect/common/TreeSelectionItem";

export const isDataFileName = fileName =>{
    if(fileName.endsWith(AKF_FILE_ENDING)
        || fileName.endsWith(EOF_FILE_ENDING)
        || fileName.endsWith(IOF_FILE_ENDING)
        || fileName.endsWith(COF_FILE_ENDING)){
        return true;
    }else{
        return false
    }
}
export function isChartDataReady(currentProps) {
    if (currentProps.belastungsfall[currentProps.belastungsfallId].fileData
        && currentProps.belastungsfall[currentProps.belastungsfallId].fileData.fileId){

        const fileId = currentProps.belastungsfall[currentProps.belastungsfallId].fileData.fileId

        if (currentProps.dataPathData && !_.isEmpty(currentProps.dataPathData)
            && currentProps.belastungsfall
            && currentProps.belastungsfall[currentProps.belastungsfallId]
            && currentProps.dataPathData[fileId]
            && currentProps.dataPathData[fileId][`${fileId}${AKF_FILE_ENDING}`]
            && currentProps.dataPathData[fileId][`${fileId}${AKF_FILE_ENDING}`].loading === false) {

            return true
        } else {
            return false
        }
    }else{
        return false
    }
}

export function isVideoDataReady(currentProps){
    const fileId = currentProps.belastungsfall[currentProps.belastungsfallId].fileData.fileId
    if(currentProps.dataPathData && !_.isEmpty(currentProps.dataPathData)
        && currentProps.belastungsfall
        && currentProps.belastungsfall[currentProps.belastungsfallId]
        && currentProps.dataPathData[fileId]
        && currentProps.dataPathData[fileId][`${fileId}${AVI_FILE_ENDING}`]
        && currentProps.dataPathData[fileId][`${fileId}${AVI_FILE_ENDING}`].loading === false){

        return true;
    }else{
        return false;
    }
}

export function getMaximumOfStringDigitArray(stringDigitArray){
    return Math.max(
        stringDigitArray.map((panelBelastungsfallId) =>{
            return parseInt(panelBelastungsfallId)
    }))
}
export function getDataFileName(fileDatasOfFileId){
    return Object.keys(fileDatasOfFileId)
        .map(fileName => {
            if(fileName.endsWith(AKF_FILE_ENDING)
                || fileName.endsWith(EOF_FILE_ENDING)
                || fileName.endsWith(IOF_FILE_ENDING)
                || fileName.endsWith(COF_FILE_ENDING)){
                return fileName;
            }})
        .filter(key => key) // filter undefined
}

export function getFileType(fileDatasOfFileId) {
    const dataFileNames = getDataFileName(fileDatasOfFileId)
    if (dataFileNames.length !== 1) {
        throw new Error('There are too many files to handle in THIS code!')
    } else {
        if (dataFileNames[0].endsWith(AKF_FILE_ENDING)) {
            return FILE_TYPE_AKF;
        } else if (dataFileNames[0].endsWith(EOF_FILE_ENDING)) {
            return FILE_TYPE_AKF;
        } else if (dataFileNames[0].endsWith(IOF_FILE_ENDING)) {
            return FILE_TYPE_AKF;
        } else if (dataFileNames[0].endsWith(COF_FILE_ENDING)) {
            return FILE_TYPE_AKF;
        } else {
            return FILE_TYPE_UNKOWN;
        }
    }
}

export function getFileEnding(fileDatasOfFileId) {
    const dataFileNames = getDataFileName(fileDatasOfFileId)
    if(dataFileNames.length !==1){
        throw new Error('There are too many files to handle in THIS code!')
    }else{
        if (dataFileNames[0].endsWith(AKF_FILE_ENDING)){
            return AKF_FILE_ENDING;
        }else if (dataFileNames[0].endsWith(EOF_FILE_ENDING)){
            return EOF_FILE_ENDING;
        }else if (dataFileNames[0].endsWith(IOF_FILE_ENDING)){
            return IOF_FILE_ENDING;
        }else if (dataFileNames[0].endsWith(COF_FILE_ENDING)){
            return COF_FILE_ENDING;
        }else{
            return UNKOWN_FILE_ENDING;
        }
    }
}

export function getKeyOfValue(valueMap, value){
    for (let k of Object.keys(valueMap)) {
        if (valueMap[k].reqId === value)
            return k;
    }
}

export function createTreeComponentsFromObjects(objectOfNodesForClicking){
    const components = {}
    components[INITIAL_ZERO] = {
        id: 0,
        name: 'root',
        indentationlevel: -10,
        parentId: 0
    }
    let hashMap2d = new TwoDimHashMap();
    Object.values(objectOfNodesForClicking).flat().forEach(item => {
        hashMap2d.set(item.parentId, item.id, (
            <StyledTreeItem
                key={item.id}
                nodeId={item.id}
                indentationlevel={item.indentaionLevel}
                label={item.label}
            />
        ))
        // make components
        components[item.id] = {
            id: item.id,
            name: item.label,
            indentationlevel: item.indentaionLevel,
            parentId: item.parentId
        }
    });


    const jsxComponents = {}
    jsxComponents[INITIAL_ZERO] = (
        <StyledTreeItem
            key={0}
            nodeId={0}
            indentationlevel={-10}
            label={'Root'}
        />
    )

    Object.keys(hashMap2d.hashMap).reverse().forEach(parentId => {
        let children = Object.keys(hashMap2d.hashMap[parentId].hashMap).map(childId => {
            return hashMap2d.get(parentId, childId);
        })
        jsxComponents[parentId] = (
            <StyledTreeItem
                key={parentId}
                nodeId={parentId}
                label={components[parentId].name}
                indentationlevel={components[parentId].indentationLevel}
                children={children}
            />
        )
    })
    return jsxComponents
}
