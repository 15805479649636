import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from '@mui/styles/makeStyles';
import {Colors} from "../../../util/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    summary:{
        backgroundColor: Colors.secondary,
    },
    hide:{
        display: 'none'
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        textAlign: 'center',
        flexBasis: '100%',
        flexShrink: 0,
        color: Colors.primary,
        fontWeight: 300
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.secondary,
    },
}));


KonfigurationKategory.propTypes = {
    configurationName: PropTypes.string.isRequired,
    hide: PropTypes.bool,
};

function KonfigurationKategory({hide, configurationName, children}) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={clsx(classes.root, {
                 [classes.hide]: hide,
             })}>
            <Accordion
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}>
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>{configurationName}</Typography>
                </AccordionSummary>
                    {children}
            </Accordion>
        </div>
    );
}
export default KonfigurationKategory;
