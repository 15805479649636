import React from "react";
import Button from '@mui/material/Button';
import AccordionActions from '@mui/material/AccordionActions';
import PropTypes from "prop-types";
import BelastungsfallSelect from './BelastungsfallSelect'
import OrtholoadAccordion from "../layout/OrtholoadAccordion";
import CancelIcon from "@mui/icons-material/Cancel";
import {connect} from "react-redux";
import {
    deleteBelastungsfallMetaData,
    deleteBelastungsfallData,
    deleteVectorScene,
    deleteMeasurementSwitches,
} from "../../../redux";
import {Divider} from "@mui/material";

Belastungsfall.propTypes = {
    belastungsfall: PropTypes.shape({
        name: PropTypes.string.isRequired,
        number: PropTypes.number
    }),
};

function Belastungsfall(props){

    const { belastungsfall,
            deleteBelastungsfallMetaData,
            deleteBelastungsfallData,
            deleteVectorScene,
            deleteMeasurementSwitches} = props;

    function deleteBelastungsfall(){
        deleteBelastungsfallMetaData(belastungsfall.number)
        deleteBelastungsfallData(belastungsfall.number)
        deleteVectorScene(belastungsfall.number)
        deleteMeasurementSwitches(belastungsfall.number)
    }

    return(
        <>
            <OrtholoadAccordion name={belastungsfall.name} dataTestID={`${belastungsfall.number}-${belastungsfall.name}-main-accordion`}>
                <BelastungsfallSelect  id="belastungsfall-select" belastungsfallId={belastungsfall.number}/>
                <Divider/>
                <AccordionActions>
                    <Button
                        variant={"contained"}
                        size="small"
                        color="secondary"
                        onClick={(e) => deleteBelastungsfall()}
                    >
                        <CancelIcon
                            id={belastungsfall.number}
                            data-testid={`${belastungsfall.number}-${belastungsfall.name}-cancel-button`}
                        />
                    </Button>
                </AccordionActions>
            </OrtholoadAccordion>
        </>
    )
}

const mapStateToProps = (state) =>{
    return {
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        deleteBelastungsfallMetaData: (belastungsfallId) => dispatch(deleteBelastungsfallMetaData(belastungsfallId)),
        deleteBelastungsfallData: (belastungsfallId) => dispatch(deleteBelastungsfallData(belastungsfallId)),
        deleteVectorScene: (belastungsfallId) => dispatch(deleteVectorScene(belastungsfallId)),
        deleteMeasurementSwitches: (belastungsfallId) => dispatch(deleteMeasurementSwitches(belastungsfallId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Belastungsfall);

