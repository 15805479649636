import React, {useState, useEffect} from "react";
import ErrorBoundary from "../../../error/error-boundary";
import {connect} from "react-redux";
import {
    checkBelastungsfallPatientFile,
    doFullTextSearch,
    fetchDataPathData,
    fetchDataPathURL,
    fetchFileDatas,
    setBelastungsfallMetaDataName,
    selectBelastungsfallImplant,
    checkBelastungsfallImplantActivity,
    checkBelastungsfallActivityParameter,
    setBelastungsfallMetaDataFileName, fetchFileDatasOfBelastungsfall
} from "../../../redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as _ from "lodash";
import {isDataFileName, getKeyOfValue} from "../../../util/helpingFunctions";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {GELENK_SELECTION_MAP, INITIAL_GELENK_NAME} from "../../../util/constants";

import makeStyles from '@mui/styles/makeStyles';
import {
    CHECK_BELASTUNGSFALL_ACTIVITY, CHECK_BELASTUNGSFALL_PARAMETER,
    SELECT_BELASTUNGSFALL_JOINT_NAME
} from "../../../redux/belastungsfall/Belastungsfall.type";
import {SET_BELASTUNGSFALL_JOINT_NAME} from "../../../redux/belastungsfallMetaData/BelastungsfallMetaData.type";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        margin: theme.spacing(1),
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            margin: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


function FullTextSearchBar(props){

    const { belastungsfallId,
            fullTextSearch,
            fileData,
            dataPathData,
            doFullTextSearch,
            fetchDataPathData,
            fetchDataPathURL,
            fetchFileDatas,
            setBelastungsfallMetaDataName,
            selectBelastungsfallImplant,
            fetchFileDatasOfBelastungsfall,
            checkBelastungsfallImplantActivity,
            checkBelastungsfallActivityParameter} = props;

    const classes = useStyles();

    const [searchTerm, setSearchTerm] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);
    const [fileId, setFileId] = useState()
    const [requestParameters, setRequestParameters] = useState({});

    useEffect(() => {
        if(searchTerm !== "") {
            doFullTextSearch(searchTerm)
        }
    }, [searchTerm]);

    useEffect(() => {
        if(fullTextSearch.searchResult instanceof Object &&
            fullTextSearch.searchResult.data instanceof Array) {
            setSearchOptions(fullTextSearch.searchResult.data)
        }
    }, [fullTextSearch]);

    useEffect(()=>{
        if (fileData
            && fileData[requestParameters.fileId]
            && fileData[requestParameters.fileId].data
            && (!dataPathData || (dataPathData && !dataPathData[requestParameters.fileId]))) {
            _.uniqBy(fileData[requestParameters.fileId].data,'path').forEach(item => {
                // only for data-files, no videos or images
                if(isDataFileName(item.name)) {
                    fetchDataPathData(item.path, item.name, requestParameters.fileId)
                }else{
                    // just save the url for convenience
                    fetchDataPathURL(item.path, item.name, requestParameters.fileId)
                }
            })

        }
    },[fileData])

    useEffect(()=>{
        if(!_.isEmpty(requestParameters))
        {
            fetchFileDatas(requestParameters.implantId,
                requestParameters.activityId,
                requestParameters.activityIndentationLevel,
                requestParameters.parameterId,
                requestParameters.parameterIndentationLevel,
                requestParameters.patientId,
                requestParameters.fileId);
            fetchFileDatasOfBelastungsfall(belastungsfallId, requestParameters.fileId)
        }
    },[requestParameters])

    const handleInput = e => {
        setSearchTerm(e.target.value);
        setTimeout(300)
    };

    const handleChange = (event, value) => {
        // wähle die jetztige Konfigurartion ab.
        wipeConfigForBelastungsfall(belastungsfallId, INITIAL_GELENK_NAME)
        // Führe Request anhand der Auswahl aus.

        // with the value we can filter the searchOptions for information needed to setup the configuration
        setRequestParameters(searchOptions.find(item => item.term === value.name));
        console.log(requestParameters)

        // setBelastungsfallMetaDataFileName(belastungsfallId, requestParameters.split(';').trim(' '))

    }

    function wipeConfigForBelastungsfall(belastungsfallId, jointName) {
        setBelastungsfallMetaDataName(belastungsfallId, jointName)
        selectBelastungsfallImplant(belastungsfallId)
    }


    return(
        <ErrorBoundary>
            <div className={classes.root}>
                <div className={classes.search}>

                    <Autocomplete
                        freeSolo
                        disableClearable
                        onChange={(event, value) => handleChange(event, value)}
                        onInput={event => handleInput(event)}
                        options={searchOptions.map((option, i) => ({ name: option.term, id: i } ))}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                            <Box component="li" {...props} key={option.id}>
                              {option.name}
                            </Box>
                          )}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Full-Text Search"
                                margin="normal"
                                variant="outlined"
                                InputProps={{ ...params.InputProps, type: 'search' }}
                            />
                        )}
                    />
                </div>
            </div>
        </ErrorBoundary>
    )
}

const mapStateToProps= (state)=>{
    return{
        fullTextSearch: state.fullTextSearch,
        fileData: state.fileDatas.fileData,
        dataPathData: state.fileDatas.dataPathData,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return{
        checkBelastungsfallPatientFile:(nodeId, belastungsfallId, checked) =>
            dispatch(checkBelastungsfallPatientFile(nodeId, belastungsfallId, checked)),
        fetchFileDatas:( implantId,activityId,activityIndentationLevel,parameterId,
                         parameterIndentationLevel,patientId,fileId) =>
            dispatch(fetchFileDatas(implantId,activityId,activityIndentationLevel,parameterId,
                parameterIndentationLevel,patientId,fileId)),
        doFullTextSearch: (term) => dispatch(doFullTextSearch(term)),
        fetchDataPathData: (filepath, filePathId, fileId) =>
            dispatch(fetchDataPathData(filepath, filePathId, fileId)),
        fetchDataPathURL: (filepath, filePathId, fileId) =>
            dispatch(fetchDataPathURL(filepath, filePathId, fileId)),
        setBelastungsfallMetaDataName: (newBelastungsfallId, name) =>
            dispatch(setBelastungsfallMetaDataName(newBelastungsfallId, name)),
        selectBelastungsfallImplant: (newBelastungsfallId) =>
            dispatch(selectBelastungsfallImplant(newBelastungsfallId)),
        checkBelastungsfallImplantActivity: (nodeId, label, indentationlevel, checked, belastungsfallId) =>
            dispatch(checkBelastungsfallImplantActivity(nodeId, label, indentationlevel, checked, belastungsfallId)),
        checkBelastungsfallActivityParameter: (nodeId, label, belastungsfallId, indentationlevel, checked) =>
            dispatch(checkBelastungsfallActivityParameter( nodeId, label, belastungsfallId,  indentationlevel, checked)),
        fetchFileDatasOfBelastungsfall: (belastungsfallId, fileId) =>
            dispatch(fetchFileDatasOfBelastungsfall(belastungsfallId, fileId)),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FullTextSearchBar);

