import {
    FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL,
    FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL,
    FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL,
    FETCH_PATIENT_FILE_OF_BELASTUNGSFALL,
    FETCH_FILE_DATA_OF_BELASTUNGSFALL,
    CHECK_BELASTUNGSFALL_ACTIVITY,
    CHECK_BELASTUNGSFALL_PARAMETER,
    CHECK_BELASTUNGSFALL_PATIENT,
    CHECK_BELASTUNGSFALL_FILE,
    CHECK_BELASTUNGSFALL_DATA,
    SET_SLICES_BW,
    DELETE_BELASTUNGSFALL_DATA, SELECT_BELASTUNGSFALL_JOINT_NAME,
} from "./Belastungsfall.type";

export const fetchJointActivitiesOfBelastungsfall = (belastungsfallId, implantId) => {
    return{
        type: FETCH_JOINT_ACTIVITY_OF_BELASTUNGSFALL,
        payload: {belastungsfallId, implantId}
    }
}

export const fetchActivityParametersOfBelastungsfall = (belastungsfallId, activityId) => {
    return{
        type: FETCH_ACTIVITY_PARAMETER_OF_BELASTUNGSFALL,
        payload: {belastungsfallId, activityId}
    }
}

export const fetchParameterPatientsOfBelastungsfall = (belastungsfallId, parameterId) => {
    return{
        type: FETCH_PARAMETER_PATIENT_OF_BELASTUNGSFALL,
        payload: {belastungsfallId, parameterId}
    }
}
export const fetchPatientFilesOfBelastungsfall = (belastungsfallId, patientId) => {
    return{
        type: FETCH_PATIENT_FILE_OF_BELASTUNGSFALL,
        payload: {belastungsfallId, patientId}
    }
}

export const fetchFileDatasOfBelastungsfall = (belastungsfallId, fileId) => {
    return{
        type: FETCH_FILE_DATA_OF_BELASTUNGSFALL,
        payload: {belastungsfallId, fileId}
    }
}

export const selectBelastungsfallImplant = ( belastungsfallId) => {
    return {
        type: SELECT_BELASTUNGSFALL_JOINT_NAME,
        payload: {belastungsfallId}
    }
}
export const checkBelastungsfallImplantActivity = (nodeId,
                                                   label,
                                                   indentationlevel,
                                                   checked,
                                                   belastungsfallId) => {
    return {
        type: CHECK_BELASTUNGSFALL_ACTIVITY,
        payload: {nodeId, label, belastungsfallId, indentationlevel, checked}
    }
}

export const checkBelastungsfallActivityParameter = (nodeId, label, belastungsfallId, indentationlevel, checked) => {
    return {
        type: CHECK_BELASTUNGSFALL_PARAMETER,
        payload: {nodeId, label, belastungsfallId, indentationlevel, checked}
    }
}

export const checkBelastungsfallParameterPatient = (nodeId, belastungsfallId, checked) => {
    return {
        type: CHECK_BELASTUNGSFALL_PATIENT,
        payload: {nodeId, belastungsfallId, checked}
    }
}
export const checkBelastungsfallPatientFile = (nodeId, label, belastungsfallId, checked) => {
    return {
        type: CHECK_BELASTUNGSFALL_FILE,
        payload: {nodeId, label, belastungsfallId, checked}
    }
}
export const checkBelastungsfallFileData = (nodeId, belastungsfallId, checked) => {
    return {
        type: CHECK_BELASTUNGSFALL_DATA,
        payload: {nodeId, belastungsfallId, checked}
    }
}
export const setSlicesBW = (belastungsfallId, slicesBW) => {
    return {
        type: SET_SLICES_BW,
        payload: {belastungsfallId, slicesBW}
    }
}

export const deleteBelastungsfallData = (belastungsfallId) =>{
    return {
        type: DELETE_BELASTUNGSFALL_DATA,
        payload: {belastungsfallId}
    }
}
