import * as d3 from "d3";

export const Colors = {
    primary: '#0088ccff',
    primaryV1: '#FF0000',
    primaryV2: '#1D3B67',
    secondary: '#EEEEEE',
    secondaryV1: '#FF6666',
    secondaryV2: '#82c4fa',
    background: '#FFFFFF',
    error: '#B00020',
    Chart:{
        black: '#000000',
        green: '#194C19',
        blue: '#2986cc',
        red: '#f44336',
        lightblack: '#000000',
        lightgreen: '#008000',
        lightblue: '#0092ff',
        lightred: '#ff0000',
    }
}
export const d3ChartColor = d3.scaleOrdinal([
    Colors.Chart.black,
    Colors.Chart.green,
    Colors.Chart.blue,
    Colors.Chart.red,
    Colors.Chart.lightblack,
    Colors.Chart.lightgreen,
    Colors.Chart.lightblue,
    Colors.Chart.lightred
]);

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function interpolateColorHEX(color1, color2, factor) {
    //hex to rgb

    if (arguments.length < 3) {
        factor = 0.5;
    }
    var result = [];

    result.push(Math.round(color1.r + factor * (color2.r - color1.r)))
    result.push(Math.round(color1.g + factor * (color2.g - color1.g)))
    result.push(Math.round(color1.b + factor * (color2.b - color1.b)))

    return rgbToHex(result[0], result[1], result[2])
};



export function interpolateColorRGB2HEX(color1, color2, factor) {
    //hex to rgb

    if (arguments.length < 3) {
        factor = 0.5;
    }
    var result = [];

    result.push(Math.round(color1.r + factor * (color2.r - color1.r)))
    result.push(Math.round(color1.g + factor * (color2.g - color1.g)))
    result.push(Math.round(color1.b + factor * (color2.b - color1.b)))

    return rgbToHex(result[0], result[1], result[2])
};



// My function to interpolate between two colors completely, returning an array
export function interpolateColorsHEX(color1, color2, steps) {
    var stepFactor = 1 / (steps - 1),
        interpolatedColorArray = [];

    for(var i = 0; i < steps; i++) {
        interpolatedColorArray.push(interpolateColorRGB2HEX(color1, color2, stepFactor * i));
    }

    return interpolatedColorArray;
}

export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

// alert(hexToRgb("#0033ff").g); // "51";


